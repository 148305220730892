import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    Button,
    Divider,
    Grid,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    withStyles
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

import MuiExpansionPanel from '@material-ui/core/Accordion';
import MuiExpansionPanelSummary from '@material-ui/core/AccordionSummary';
import ExpansionPanelDetails from '@material-ui/core/AccordionActions';

import TextField from "@material-ui/core/TextField";

import List from '@material-ui/core/List';

import Badge from '@material-ui/core/Badge';
import { DatePicker } from "@material-ui/pickers";

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ForumIcon from '@material-ui/icons/Forum';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import WarningIcon from '@material-ui/icons/Warning';
import RestoreIcon from '@material-ui/icons/Restore';

import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';

import Axios from 'axios';

import NumberFormat from 'react-number-format';
import { clone, debounce, formatDate } from "../shared/Helpers";
import DynamicTableAnswer from "../nc-table-creator/DynamicTableAnswer";
import StaticTableAnswer from "../nc-table-creator/StaticTableAnswer";
import TooltipIconButton from "../shared/TooltipIconButton";
import jexcel from "jexcel";
import PollComments from "./PollComments";
import MaskedInput from 'react-text-mask';
import { DropzoneArea } from "material-ui-dropzone";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DownloadFileIcon from '@material-ui/icons/CloudDownload';
import ConfirmDialog from "../shared/ConfirmDialog";
import ReadMore from "../shared/ReadMore";
import BaseComponent from "../shared/BaseComponent";

const TextMask = (props) => {
    const { inputRef, ...other } = props;

    return <MaskedInput
        ref={ref => inputRef(ref ? ref.inputElement : null)}
        {...other}
    />
};

class NCPollAnswer extends BaseComponent {
    dateFormat = 'yyyy-MM-dd';
    form = React.createRef();
    dropzoneRef = React.createRef();
    url = this.props.museumPollId ? '/museum-polls/' + this.props.museumPollId : null; // preview has empty url
    emptyAnswer = '';

    state = {
        counter: 0,
        answer: null,
        answerBackup: false,
        newAnswer: null,
        newAnswerBackup: false,
        details: null,
        newDetails: null,
        expanded: null,
        isAnswered: false,
        updateKey: '',
        filesToUpload: [],
        autoSave: this.props.autoSave,
        warningDialog: false,
    };

    details = {
        no_data: 'Brak danych',
        unrelated: 'Nie dotyczy',
        estimated: 'Wartość szacunkowa',
    };

    constructor(props) {
        super(props);

        switch (props.pollQuestion.type) {
            case 'file':
            case 'static_table':
            case 'dynamic_table':
                this.state.answer = props.answer || [];
                this.state.newAnswer = clone(props.answer, []);
                this.emptyAnswer = [];
                break;
            case 'choice_fixed':
            case 'choice_single':
            case 'choice_multiple':
                this.state.answer = props.answer || {};
                this.state.newAnswer = clone(props.answer, {});
                this.emptyAnswer = {};
                break;
            case 'date':
            case 'year':
                this.dateFormat = 'yyyy';
                this.emptyAnswer = null;
            default:
                this.state.answer = props.answer || this.emptyAnswer;
                this.state.newAnswer = props.answer || this.emptyAnswer;
        }

        if (!props.pollQuestion.details) {
            props.pollQuestion.details = [];
        }

        // if (this.props.pollQuestion.type !== 'number') {
        if (!props.pollQuestion.details.includes('estimated') || ['dynamic_table', 'static_table'].includes(props.pollQuestion.type)) {
            delete this.details.estimated;
        }

        // if (['dynamic_table', 'static_table'].includes(this.props.pollQuestion.type)) {
        if (!props.pollQuestion.details.includes('no_data')) {
            delete this.details.no_data;
        }

        if (!props.pollQuestion.details.includes('unrelated')) {
            delete this.details.unrelated;
        }

        if (['text', 'open'].includes(props.pollQuestion.type)) {
            this.state.counter = this.state.answer.length;
        }

        this.state.details = props.details;
        this.state.newDetails = props.details;

        this.state.expanded = props.expanded !== undefined ? props.expanded : null;
        this.state.isAnswered = this.isAnswered();
        if (!this.state.isAnswered && this.state.expanded === null) {
            this.state.expanded = true;
            this.props.expandHandler(true);
        }

        if (props.pollQuestion.parent_answer) {
            this.state.newAnswer = props.pollQuestion.parent_answer;
            this.state.autoSave = false;
        }

        this.state.updateKey = this.props.updateKey;
    }

    handleExpand = (event, expanded) => {
        this.setState({ expanded: !!expanded }, this.props.expandHandler(expanded));
    };

    updateHandler = (reload) => {
        this.props.updateHandler(reload);
    };

    setFixedAnswer = (choiceId) => event => {
        let choices = this.state.newAnswer;
        choices[choiceId] = event.target.value;

        this.handleNewAnswer(choices);
    };

    setChoiceAnswerValue = (choiceId) => event => {
        let choices = this.state.newAnswer;
        choices[choiceId] = event.target.value;

        this.setState({ newAnswer: choices });
    };

    setDateAnswer = event => {
        this.handleNewAnswer(formatDate(this.dateFormat, event));
    };

    setTableAnswer = values => {
        this.handleNewAnswer(values);
    };

    setBooleanAnswer = event => {
        this.handleNewAnswer(event.target.value);
    };

    setNewAnswer = event => {
        let value;

        let choices = {};
        let counter = {};
        switch (this.props.pollQuestion.type) {
            case 'choice_multiple':
                choices = clone(this.state.newAnswer);
            case 'choice_single':
                value = event.target.value;

                if (event.target.checked) {
                    choices[value] = choices[value] || '';
                } else {
                    delete choices[value];
                }

                value = Object.keys(choices).sort().reduce((r, k) => (r[k] = choices[k], r), {});

                return this.handleNewAnswer(value);
            case 'number':
                value = event.value;
                break;
            case 'text':
            case 'open':
                value = event.target.value;
                if (this.props.pollQuestion.properties && this.props.pollQuestion.properties.maxlength) {
                    counter.counter = value.length;
                }
                break;
            default:
                value = event.target.value;
        }

        this.handleNewAnswer(value, { ...counter });
    };

    handleNewAnswer = (newAnswer, state = {}) => {
        if (newAnswer === undefined) {
            newAnswer = this.state.newAnswer;
        }
        const newState = Object.assign({ newAnswer }, { ...state });

        if (this.isAnswerChanged(newAnswer) && newState.newDetails !== 'estimated') {
            newState.newDetails = null;
        }

        this.setState(newState, () => this.state.autoSave && this.isAnswerChanged(newAnswer) ? this.handleSave() : null);
    };

    setDetails = event => {
        let newDetails = event.target.value;
        if (this.state.newDetails === newDetails) {
            newDetails = null;
        }
        let answer = { updateKey: '' + -new Date() };
        let backup = {};
        if (newDetails !== null && newDetails !== 'estimated') {
            if (this.state.answerBackup === false) {
                backup.answerBackup = clone(this.state.answer);
                backup.newAnswerBackup = clone(this.state.newAnswer);
            }
            answer.newAnswer = clone(this.emptyAnswer, null);
        }

        this.setState({
            newDetails,
            ...answer,
            ...backup,
        }, () => this.state.autoSave ? this.handleSave() : null);
    };

    handleBlur = event => {
        if (this.state.autoSave && this.isAnswerChanged(this.state.newAnswer)) {
            if (this.state.newDetails !== 'estimated') {
                this.setState({ newDetails: null });
            }
            this.handleSave(event);
        }
    };

    handleCancel = () => {
        this.setState({
            newAnswer: clone(this.state.answer, this.emptyAnswer),
            newDetails: this.state.details,
            updateKey: '' + -new Date(),
        });
    };

    handleUndo = () => {
        this.setState({
            answer: this.state.answerBackup,
            newAnswer: this.state.newAnswerBackup,
            updateKey: '' + -new Date(),
            answerBackup: false,
            newAnswerBackup: false,
            details: null,
            newDetails: null,
        }, () => this.state.autoSave ? this.handleSave() : null);
    };

    deleteFile = fileId => event => {
        Axios.delete(this.url + '/files', {
            data: {
                file_id: fileId,
                question_id: this.props.pollQuestion.question_id,
            }
        }).then((response) => {
            this.setState({
                answer: response.data.answer,
                newAnswer: response.data.answer,
                details: response.data.details,
                isAnswered: this.isAnswered(response.data.answer),
                updateKey: '' + -new Date(),
            }, this.updateHandler);
        }).catch(function () {
            alert('Błąd podczas usuwania pliku.');
        });
    };

    downloadFile = fileId => event => {
        Axios.get(this.url + '/files').then(response => {
            let form = document.createElement('form');
            form.method = 'post';
            form.action = process.env.REACT_APP_SERVER_URL + this.url + '/download/' + fileId;
            let input = document.createElement('input');
            input.name = 'token';
            input.value = localStorage.getItem('id_token');
            form.appendChild(input);
            document.body.appendChild(form);
            form.submit();
            form.remove();
        });
    };

    handleFilesChange = (files) => {
        this.setState({ filesToUpload: files });
    };

    handleSaveFiles = () => {
        let formData = new FormData();
        formData.append('question_id', this.props.pollQuestion.question_id);

        this.state.filesToUpload.forEach((file, key) => {
            formData.append('file' + key, file);
        });

        Axios.post(this.url + '/updateAnswer', formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        }).then((response) => {
            this.dropzoneRef.current.setState({ fileObjects: [] });
            this.setState({
                answer: response.data.answer,
                newAnswer: response.data.answer,
                details: response.data.details,
                filesToUpload: [],
                isAnswered: this.isAnswered(response.data.answer),
                updateKey: '' + -new Date(),
            }, this.updateHandler);
        }).catch(function () {
            alert('Błąd podczas wysyłania plików.');
        });
    };

    handleSave = debounce(event => {
        if (!this.form.current || !this.url) {
            return;
        }
        const isValid = this.form.current.reportValidity();
        if (!isValid) {
            // this.setState({ newAnswer: this.state.answer });
            return;
        }
        let autoSave = this.state.autoSave;
        if (this.props.pollQuestion.parent_answer) {
            autoSave = true;
        }

        const warningDialog = this.props.validateHandler(this.props.pollQuestion, clone(this.state.newAnswer, ''));
        if (warningDialog !== true) {
            this.setState({ warningDialog, newAnswer: this.state.answer });
            return;
        }

        Axios.post(this.url + '/updateAnswer', {
            question_id: this.props.pollQuestion.question_id,
            answer: clone(this.state.newAnswer, ''),
            details: this.state.newDetails,
        }).then((response) => {
            this.setState({
                autoSave,
                answer: clone(response.data.answer, this.emptyAnswer),
                details: response.data.details,
                isAnswered: this.isAnswered(clone(response.data.answer, this.emptyAnswer)),
                updateKey: '' + -new Date(),
            }, this.updateHandler);
        });
    }, 1000);

    lockSection = debounce(() => {
        if (!this.url) {
            return;
        }
        Axios.put(this.url + '/lock-section/' + this.props.pollQuestion.section_id, {}, { timeout: 10000 }).catch();
    }, 1000);

    isAnswered = (answer, details) => {
        const { type, properties } = this.props.pollQuestion;
        let isAnswered = true;
        if (details === undefined) {
            details = this.state.newDetails;
        }

        if (details === null || details === 'estimated') {
            if (answer === undefined) {
                answer = this.state.answer || this.emptyAnswer;
            }

            switch (type) {
                case 'boolean':
                case 'number':
                case 'text':
                case 'mask':
                case 'regexp':
                case 'email':
                case 'open':
                case 'date':
                case 'year':
                    isAnswered = answer !== null && answer.length;
                    break;
                case 'file':
                    isAnswered = answer.length > 0;
                    break;
                case 'choice_fixed':
                    isAnswered = properties.choices.length === Object.values(answer).length;
                    break;
                case 'choice_single':
                case 'choice_multiple':
                    isAnswered = !!Object.values(answer).length;
                    break;
                case 'static_table':
                    if (!properties.table.data.length && (details === null || details === 'estimated')) {
                        isAnswered = false;
                    }
                    properties.table.data.forEach((row, y) => {
                        row.forEach((col, x) => {
                            if (!answer || answer[y] === undefined || answer[y][x] === undefined) {
                                return isAnswered = false; // no answer yet
                            }
                            const value = answer[y][x];
                            const cellName = jexcel.getColumnNameFromId([x, y]);
                            const { type } = properties.table.meta[cellName] || {};
                            switch (type) {
                                case 'text':
                                case 'number':
                                case 'date':
                                case 'year':
                                case 'regexp':
                                case 'mask':
                                case 'email':
                                    if (!value.toString().length) {
                                        isAnswered = false;
                                    }
                                    break;
                                case 'choice_single':
                                case 'choice_multiple':
                                    if (!Object.keys(value.value || []).length) {
                                        isAnswered = false;
                                    }
                                    break;
                            }
                        });
                    });
                    break;
                case 'dynamic_table':
                    let columnTypes = [];
                    properties.table.data.forEach((row, y) => {
                        row.forEach((col, x) => {
                            const cellName = jexcel.getColumnNameFromId([x, y]);
                            const cellMeta = properties.table.meta[cellName] || {};
                            const { type } = cellMeta;
                            if (y) { // dynamic table has 2 rows
                                columnTypes[x] = type;
                            }
                        });
                    });

                    if (!answer.length) {
                        isAnswered = false;
                    }
                    answer.forEach((row, y) => {
                        row.forEach((col, x) => {
                            const value = answer[y][x];
                            switch (columnTypes[x]) {
                                case 'text':
                                case 'number':
                                case 'date':
                                case 'year':
                                case 'regexp':
                                case 'mask':
                                case 'email':
                                    if (!value.toString().length) {
                                        isAnswered = false;
                                    }
                                    break;
                                case 'choice_single':
                                case 'choice_multiple':
                                    if (!Object.keys(value.value || []).length && !['unrelated', 'no_data'].includes(value.details)) {
                                        isAnswered = false;
                                    }
                                    break;
                            }
                        });
                    });
                    break;
                case 'label':
                default:
            }
        }

        this.props.answerHandler(isAnswered, answer, details);

        return isAnswered;
    };

    isAnswerChanged = newAnswer => {
        return JSON.stringify(this.state.answer) !== JSON.stringify(newAnswer !== undefined ? newAnswer : this.state.newAnswer)
            || this.state.details !== this.state.newDetails;
    };

    render() {
        const { classes, isLocked, isMarked, unlockComments, number, museumPollId, pollQuestion, updateKey } = this.props;
        const { autoSave, newAnswer, expanded, isAnswered, warningDialog, newDetails, filesToUpload, counter } = this.state;
        const { comments } = pollQuestion;
        const { dateFormat } = this;
        const isAnswerChanged = this.isAnswerChanged();
        // const disabled = isLocked || (newDetails && newDetails !== 'estimated');
        const disabled = isLocked;

        let answerContent = '';
        let choices;
        let maxlength = {};
        let properties = pollQuestion.properties || {};
        if (properties && properties.maxlength) {
            maxlength.maxLength = properties.maxlength;
        }

        switch (pollQuestion.type) {
            case 'boolean':
                answerContent = <RadioGroup
                    name={pollQuestion.question_id + "_boolean"}
                    className={classes.group}
                    value={newAnswer + ""}
                    onChange={this.setBooleanAnswer}
                >
                    <div className={classes.choiceRow}><Radio value="1" disabled={disabled}/><FormLabel focused={false} className={classes.choice} disabled={disabled}>Tak</FormLabel></div>
                    <div className={classes.choiceRow}><Radio value="0" disabled={disabled}/><FormLabel focused={false} className={classes.choice} disabled={disabled}>Nie</FormLabel></div>
                </RadioGroup>;
                break;
            case 'choice_fixed':
                choices = newAnswer;

                answerContent = <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            {Object.entries(properties.choice_options).map(([optionKey, option]) => {
                                return <TableCell align="center" key={optionKey}>
                                    <Typography>{option.name}</Typography>
                                </TableCell>;
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.entries(properties.choices).map(([choiceKey, choice]) => {
                            return <TableRow key={choiceKey}>
                                <TableCell><Typography>{choice.name}</Typography></TableCell>
                                {Object.entries(properties.choice_options).map(([optionKey, option]) => {
                                    return <TableCell align="center" key={optionKey}>
                                        <Radio
                                            name={"choice_" + choiceKey}
                                            onChange={this.setFixedAnswer(choice.name)}
                                            value={option.name}
                                            checked={choices[choice.name] === option.name + ''}
                                            disabled={disabled}
                                        />
                                    </TableCell>;
                                })}
                            </TableRow>;
                        })}
                    </TableBody>
                </Table>;
                break;
            case 'choice_single':
            case 'choice_multiple':
                choices = newAnswer;

                answerContent = <FormControl disabled={disabled} component="fieldset" fullWidth>
                    <FormGroup className={classes.group}>
                        {Object.entries(properties.choices).map(([choiceKey, choice]) => {
                            return <div key={choiceKey} className={classes.choiceRow}>
                                {pollQuestion.type === 'choice_multiple' ?
                                    <Checkbox
                                        onChange={this.setNewAnswer}
                                        checked={choices[choice.name] !== undefined}
                                        value={choice.name + ''}
                                    /> :
                                    <Radio
                                        onChange={this.setNewAnswer}
                                        checked={choices[choice.name] !== undefined}
                                        value={choice.name + ''}
                                    />}
                                <FormLabel focused={false} className={classes.choice} disabled={disabled}>
                                    <span dangerouslySetInnerHTML={{ __html: choice.name }}/>
                                </FormLabel>
                                {choice.has_text ?
                                    <TextField
                                        multiline={true}
                                        required={true}
                                        className={classes.choiceText}
                                        onChange={this.setChoiceAnswerValue(choice.name)}
                                        onFocus={this.lockSection}
                                        onBlur={this.handleBlur}
                                        value={choices[choice.name] || ''}
                                        disabled={isLocked || choices[choice.name] === undefined}
                                    /> : ''
                                }
                            </div>;
                        })}
                    </FormGroup>
                </FormControl>;
                break;
            case 'number':
                answerContent = <NumberFormat
                    name={pollQuestion.question_id + "_number"}
                    placeholder="Wpisz wartość"
                    onValueChange={this.setNewAnswer}
                    onFocus={this.lockSection}
                    onBlur={this.handleBlur}
                    value={newAnswer || ''}
                    thousandSeparator="'"
                    decimalSeparator=","
                    suffix={properties.suffix ? ' ' + properties.suffix : ''}
                    decimalScale={parseInt(properties.precision) || 0}
                    fixedDecimalScale={!!properties.precision}
                    allowNegative={true}
                    isNumericString={true}
                    disabled={disabled}
                />;
                break;
            case 'file':
                answerContent = <Grid container spacing={4}>
                    <Grid item xs={7}>
                        <List>
                            {newAnswer.map((file, k) => <React.Fragment key={k}>
                                <ListItem>
                                    <ListItemIcon>
                                        <TooltipIconButton title="Pobierz" onClick={this.downloadFile(file.id)}>
                                            <DownloadFileIcon/>
                                        </TooltipIconButton>
                                    </ListItemIcon>

                                    <ListItemText primary={file.originalName} secondary={file.size_string}/>
                                    <ListItemText secondary={file.created_at} secondaryTypographyProps={{ align: 'right' }}/>

                                    <ListItemSecondaryAction>
                                        <TooltipIconButton title="Usuń" edge="end" onClick={this.deleteFile(file.id)} disabled={disabled}>
                                            <DeleteIcon color="secondary"/>
                                        </TooltipIconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider variant="fullWidth" component="li"/>
                            </React.Fragment>)}
                        </List>
                    </Grid>
                    <Grid item xs={5}>
                        <DropzoneArea
                            ref={this.dropzoneRef}
                            onChange={this.handleFilesChange}
                            dropzoneText="Kliknij lub przeciągnij pliki tutaj"
                            maxFileSize={File.maxFileSize}
                            // showFileNamesInPreview={true}
                            // showFileNames={true}
                            dropzoneClass={classes.dropZone}
                            dropzoneParagraphClass={classes.dropZoneParagraph}
                            acceptedFiles={File.allowedMime}
                            getFileLimitExceedMessage={disabled ? () => 'Wrzucanie plików jest wyłączone' : filesLimit => `Maksymalnie ${filesLimit} pliki na raz`}
                            getFileAddedMessage={fileName => `Plik "${fileName}" został dodany`}
                            getFileRemovedMessage={fileName => `Plik "${fileName}" został usunięty`}
                            getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => `Plik "${rejectedFile.name}" nie może być dodany`}
                            filesLimit={disabled ? 0 : 3}
                        />
                        <Button variant="contained" color="primary" style={{ marginTop: 20 }} disabled={disabled || !filesToUpload.length}
                                onClick={this.handleSaveFiles}>Zapisz pliki</Button>
                    </Grid>
                </Grid>;
                break;
            case 'regexp':
                answerContent = <TextField
                    name={pollQuestion.question_id + "_regexp"}
                    placeholder="Wpisz odpowiedź"
                    value={newAnswer || ''}
                    onChange={event => {
                        // event.target.reportValidity();
                        this.setNewAnswer(event);
                    }}
                    onFocus={this.lockSection}
                    onBlur={this.handleBlur}
                    type="text"
                    margin="normal"
                    disabled={disabled}
                    inputProps={{
                        pattern: properties.pattern,
                        onInvalid: event => event.target.setCustomValidity(properties.message),
                    }}
                />;
                break;
            case 'email':
                answerContent = <TextField
                    name={pollQuestion.question_id + "_email"}
                    placeholder="Wpisz adres email"
                    value={newAnswer || ''}
                    onChange={event => {
                        // event.target.reportValidity();
                        this.setNewAnswer(event);
                    }}
                    onFocus={this.lockSection}
                    onBlur={this.handleBlur}
                    type="email"
                    margin="normal"
                    disabled={disabled}
                />;
                break;
            case 'mask':
                let mask, placeholder, pattern;
                switch (properties.variant) {
                    case 'postcode':
                        mask = [/\d/, /\d/, '-', /\d/, /\d/, /\d/];
                        placeholder = '00-000';
                        pattern = '[0-9\\-]{6}';
                        break;
                    case 'phone':
                        mask = [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/];
                        placeholder = '000 000 000';
                        pattern = '[0-9 ]{11}';
                        break;
                }
                answerContent = <TextField
                    name={pollQuestion.question_id + "_mask"}
                    placeholder={placeholder}
                    value={newAnswer || ''}
                    type="text"
                    margin="normal"
                    onChange={event => {
                        // event.target.reportValidity();
                        this.setNewAnswer(event);
                    }}
                    onFocus={this.lockSection}
                    onBlur={this.handleBlur}
                    InputProps={{
                        inputComponent: TextMask,
                    }}
                    inputProps={{
                        mask: mask,
                        pattern: pattern,
                        // guide: true,
                        // showMask: true,
                    }}
                    disabled={disabled}
                />;
                break;
            case 'text':
                answerContent = <TextField
                    multiline
                    name={pollQuestion.question_id + "_number"}
                    placeholder="Wpisz odpowiedź"
                    value={newAnswer || ''}
                    onChange={this.setNewAnswer}
                    onFocus={this.lockSection}
                    onBlur={this.handleBlur}
                    type="text"
                    margin="normal"
                    disabled={disabled}
                    className={classes.textField}
                    inputProps={{ ...maxlength }}
                />;
                break;
            case 'open':
                answerContent = <TextField
                    name={pollQuestion.question_id + '_open'}
                    placeholder="Wpisz odpowiedź"
                    value={newAnswer || ''}
                    onChange={this.setNewAnswer}
                    onFocus={this.lockSection}
                    onBlur={this.handleBlur}
                    margin="normal"
                    multiline
                    disabled={disabled}
                    className={classes.openField}
                    inputProps={{ ...maxlength }}
                />;
                break;
            case 'year':
            case 'date':
                answerContent = <DatePicker
                    name={pollQuestion.question_id + '_' + pollQuestion.type}
                    views={pollQuestion.type === 'year' ? ['year'] : ['year', 'month', 'day']}
                    initialFocusedDate={formatDate(dateFormat)}
                    placeholder={"Wybierz " + (pollQuestion.type === 'year' ? 'rok' : 'datę')}
                    value={newAnswer || null}
                    format={dateFormat}
                    onChange={this.setDateAnswer}
                    margin="normal"
                    disabled={disabled}
                />;
                break;
            case 'dynamic_table':
                answerContent = <DynamicTableAnswer
                    key={this.state.newDetails} // fixes issues after changing details
                    details={pollQuestion.details}
                    answerDetails={this.props.details}
                    table={clone(properties.table)}
                    values={newAnswer || []}
                    onChange={this.setTableAnswer}
                    onFocus={this.lockSection}
                    disabled={disabled}
                />;
                break;
            case 'static_table':
                answerContent = <StaticTableAnswer
                    key={this.state.newDetails} // fixes issues after changing details
                    details={pollQuestion.details}
                    answerDetails={this.props.details}
                    table={clone(properties.table)}
                    values={newAnswer || []}
                    onChange={this.setTableAnswer}
                    onFocus={this.lockSection}
                    disabled={disabled}
                />;
                break;
        }

        return <div className={classes.answerContainer}>
            <ExpansionPanel className={classes.panel} expanded={!!expanded} onChange={this.handleExpand}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                    <div className={classes.panelTitle}>
                        <Typography className={classes.heading}>
                            <Badge max={10000} badgeContent={number} color={isAnswerChanged ? "secondary" : "primary"}
                                   className={classes.badgeContainer}
                                   classes={{ badge: classNames(classes.numberBadge, isAnswered && !isAnswerChanged ? classes.greenBadge : null) }}>
                                <span dangerouslySetInnerHTML={{ __html: pollQuestion.name }}/>
                            </Badge>
                            {/*{pollQuestion.name.split("\n").map((line, key) => <span key={key}>{line}<br/></span>)}*/}
                        </Typography>
                    </div>
                    <div className={classes.panelComment} align="right">
                        {isMarked && (
                            <Tooltip title="Zmienione">
                                <WarningIcon color="secondary"/>
                            </Tooltip>
                        )}
                        {!!comments && !!comments.length && (
                            <Badge className={classes.badge} badgeContent={comments.length || 1} color={pollQuestion.hasDraft ? 'secondary' : 'primary'}>
                                <ForumIcon color="action"/>
                            </Badge>
                        )}
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div className={classes.panelTitle} style={{ position: 'relative', paddingBottom: 70 }}>
                        <div><Typography dangerouslySetInnerHTML={{ __html: pollQuestion.description }}/></div>
                        {pollQuestion.hint && (
                            <ReadMore html={pollQuestion.hint} className={classes.hint}/>
                        )}
                        <form ref={this.form} onSubmit={(event) => event.preventDefault()}>
                            {answerContent}
                        </form>
                        {properties && properties.maxlength && (
                            <div className={classes.counter}>Użyto znaków: {counter}/{properties.maxlength}</div>
                        )}
                        <div className={classes.buttonsWrapper}>
                            {!autoSave && (<>
                                <TooltipIconButton title="Zapisz zmiany" size="small" onClick={this.handleSave} disabled={!isAnswerChanged}
                                               className={classes.green}>
                                    <CheckIcon/>
                                </TooltipIconButton>
                                <TooltipIconButton title="Cofnij zmiany" size="small" onClick={this.handleCancel} disabled={!isAnswerChanged}
                                               color="secondary" style={{ marginRight: 24 }}>
                                    <CloseIcon/>
                                </TooltipIconButton>
                            </>)}
                            <FormControl disabled={isLocked} component="fieldset" className={classes.details}>
                                <RadioGroup row>
                                    {Object.entries(this.details).map(([value, label]) => {
                                        return <FormControlLabel
                                            key={value}
                                            control={<Radio
                                                // onChange={this.setDetails}
                                                onClick={this.setDetails}
                                                checked={newDetails === value}
                                                value={value}
                                            />}
                                            label={label}
                                        />
                                    })}
                                </RadioGroup>
                            </FormControl>

                            {this.answerBackup !== false && ['no_data', 'unrelated'].includes(this.state.details) && (
                                <TooltipIconButton title="Cofnij" size="small" onClick={this.handleUndo}
                                                   color="secondary" style={{ marginRight: 24 }}>
                                    <RestoreIcon/>
                                </TooltipIconButton>
                            )}
                        </div>
                    </div>
                    <div className={classes.panelComment} align="right">
                        {!!comments && (
                            <PollComments
                                key={pollQuestion.question_id + updateKey}
                                updateKey={updateKey}
                                comments={comments}
                                museumPollId={museumPollId}
                                commentableId={pollQuestion.question_id}
                                commentableType="PollQuestion"
                                updateHandler={this.updateHandler}
                                isLocked={isLocked}
                                unlockComments={unlockComments}
                            />
                        )}
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>

            {!!warningDialog && (
                <ConfirmDialog open={true}
                               title="Ostrzeżenie"
                               onClose={() => this.setState({ warningDialog: false })}
                               onConfirm={() => {}}
                               cancelTitle="Zamknij"
                               confirmTitle="">
                    {warningDialog}
                </ConfirmDialog>
            )}
        </div>
    }
}

NCPollAnswer.propTypes = {
    pollQuestion: PropTypes.object.isRequired,
    answer: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.array,
    ]),
    number: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    autoSave: PropTypes.bool,
    isMarked: PropTypes.bool,
    isLocked: PropTypes.bool,
    unlockComments: PropTypes.bool,
    expanded: PropTypes.bool,
    validateHandler: PropTypes.func,
    updateHandler: PropTypes.func,
    answerHandler: PropTypes.func,
    expandHandler: PropTypes.func,
    updateKey: PropTypes.any,
};
NCPollAnswer.defaultProps = {
    children: [],
    number: 1,
    autoSave: false,
    isMarked: false,
    isLocked: false,
    unlockComments: false,
    validateHandler: () => {},
    updateHandler: () => {},
    answerHandler: () => {},
    expandHandler: () => {},
    updateKey: '',
};


const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    answerContainer: {
        display: 'block',
        width: '100%',
        flexBasis: '100%',
        marginTop: 10,
        marginLeft: '0 !important',
    },
    group: {
        // alignItems: 'flex-start',
        marginLeft: -11,
    },
    choiceRow: {
        display: 'flex',
    },
    choice: {
        color: 'inherit',
        alignSelf: 'center',
    },
    choiceText: {
        alignSelf: 'center',
        flexGrow: 1,
        marginLeft: 6,
        marginTop: 2
    },
    smallButton: {
        width: 20,
        height: 20,
        minWidth: 0,
        minHeight: 0,
        marginRight: 0,
    },
    smallIcon: {
        width: 20,
        height: 20,
    },
    pointer: {
        cursor: 'pointer',
    },
    collapse: {
        marginBottom: theme.spacing(),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        marginLeft: -10,
        '& div': {
            display: 'inline-block',
        },
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    panelDetails: {
        display: 'block',
    },
    panelTitle: {
        flexGrow: 1,
    },
    panelComment: {
        flexBasis: '30%',
        flexShrink: 1,
    },
    panel: {
        width: '100%',
        paddingLeft: theme.spacing(1),
    },
    table: {
        width: '100%'
    },
    badge: {
        marginLeft: 10
    },
    red: {
        color: red[500]
    },
    green: {
        color: green[500]
    },
    badgeContainer: {
        flexDirection: 'row-reverse',
    },
    numberBadge: {
        position: 'relative',
        transform: 'none',
        marginRight: theme.spacing(1),
        marginTop: 1,
    },
    greenBadge: {
        background: green[500]
    },
    button: {
        // marginTop: theme.spacing(2),
        marginRight: theme.spacing(),
    },
    editButton: {
        position: 'absolute',
        left: -10,
        bottom: -10,
    },
    editMenuIcon: {
        marginRight: 10
    },
    editIcon: {
        padding: 4
    },
    buttonsWrapper: {
        display: 'block',
        position: 'absolute',
        left: 0,
        bottom: 0,
    },
    details: {
        verticalAlign: 'middle',
    },
    inputField: {
        display: 'flex',
        color: theme.palette.text.secondary,
        fontSize: theme.typography.pxToRem(15),
        paddingTop: 1,
        paddingRight: 2,
        paddingLeft: 2,
        paddingBottom: 1,
        border: 0,
        width: '100%',
        outline: '1px solid #eee',
        marginTop: -1,
        marginRight: -2,
        marginLeft: -2,
        marginBottom: -1,
        lineHeight: '150%',
    },
    hint: {
        color: grey[500],
        '& .handle': {
            cursor: 'pointer',
        }
    },
    cellContent: {
        width: '100%',
    },
    inputNumber: {
        width: 110,
    },
    numberSuffix: {
        display: 'inline-block',
        marginTop: 16,
        marginBottom: 8,
        fontSize: '1em',
        lineHeight: '30px',
        marginLeft: 4,
    },
    dropZone: {
        '& img': {
            height: 'auto',
        },
    },
    dropZoneParagraph: {
        paddingTop: 80,
        fontSize: '1em',
    },
    textField: {
        width: '100%',
    },
    openField: {
        width: '100%',
        outline: '1px dashed #ddd',
        '& textarea': {
            paddingLeft: theme.spacing(),
            paddingRight: theme.spacing(),
            minHeight: '4em',
        },
    },
});

const ExpansionPanel = withStyles({
    root: {
        width: '100%',
    },
    expanded: {
        margin: 0
    },
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
    root: {
        minHeight: 0,
        '&$expanded': {
            minHeight: 0,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiExpansionPanelSummary);


export default withStyles(styles)(NCPollAnswer);
