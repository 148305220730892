import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import NCForm from '../nc-form/NCForm';
import Button from '@material-ui/core/Button';
import PivotReport from './PivotReport';
import GISReport from './GISReport';
import ScaleReport from './ScaleReport';
import AnswersReport from './AnswersReport';
import AddIcon from '@material-ui/icons/Add';
import ChartIcon from '@material-ui/icons/BarChart';
import Chip from '@material-ui/core/Chip';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import FabContainer from "../shared/FabContainer";
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import FabButton from "../shared/FabButton";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import QuestionPicker from "../questions/QuestionPicker";
import PollSectionPicker from "../polls/PollSectionPicker";
import PollMuseumsPicker from "../polls/PollMuseumsPicker";
import PollPicker from "../polls/PollPicker";
import ConfirmDialog from "../shared/ConfirmDialog";
import TextField from '@material-ui/core/TextField';
import Axios from 'axios';
import { Checkbox, FormControlLabel } from "@material-ui/core";

import XLSX from 'xlsx';
import html2canvas from "html2canvas";
import QuestionsPicker from "../questions/QuestionsPicker";
import { clone, dotSet, getObjValuesFromArrayByAttribute, getSelectOptions, removeItemsFromArrayByAttribute } from "../shared/Helpers";
import TooltipIconButton from "../shared/TooltipIconButton";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import jexcel from 'jexcel';
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Museum from "../../models/Museum";
import BaseComponent from "../shared/BaseComponent";
import ReportsAnswerConditionsPicker from "./ReportsAnswerConditionsPicker";

class DataReports extends BaseComponent {
    static permissions = ['data-reports'];
    years = [];

    state = {
        isLoading: true,
        dialogPivot: false,
        dialogGIS: false,
        dialogAnswers: false,
        questions: [],
        museums: [],
        sections: [],
        polls: [],
        conditions: [],
        confirmDialog: false,
        templateName: '',
        templateID: '',
        currentTemplate: { value: '', children: '' },

        templates: [],

        saveDialog: false,
        loadDialog: false,

        // data : {catalogs: {}, museums: {}, projects: {}, polls : {}},
        questionsPicker: false,
        cellPicker: false,
        museumsPicker: false,
        pollsPicker: false,
        values: {
            poll: {
                // type: ['branched', 'branchless', 'branch'],
                // state: 'all',
            },
            report_type: 'answers',
            question_type: [],
            estimated_data: 1,
        },
        additionalData: { gis: {}, pivot: {}, scale: {}, answers: {} },
    };

    constructor(props) {
        super(props);

        Axios.get('/data-reports').then((response) => {
            this.years = response.data.years;
            let values = this.state.values;
            values['poll.year'] = this.years;

            this.setState({
                isLoading: false,
                values,
            });
        }).catch(this.catchErrors);
    }

    closeGISDialog = () => {
        this.setState({ dialogGIS: false });
    }

    closeAnswersDialog = () => {
        this.setState({ dialogAnswers: false });
    }

    onDataLoaded = () => {
        let values = this.state.values;

        values.report_type = 'pivot';
        values.question_type = [];
        values.estimated_data = 1;
        // values.poll.type = ['branched', 'branchless', 'branch'];
        // values.poll.state = 'all';

        this.setState({
            values
        });
    }

    componentDidMount() {
        this.pivotRef = React.createRef();
        this.gisRef = React.createRef();
        this.answersRef = React.createRef();
        this.scaleRef = React.createRef();
    }

    downloadGIS = () => {
        if (document.getElementsByClassName('map-wrapper').length) {
            html2canvas(document.getElementsByClassName('map-wrapper')[0], {
                scale: 3
            }).then((canvas) => {
                var a = document.createElement('a');

                a.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
                a.download = 'raport.jpg';
                document.body.appendChild(a);
                a.click();

                setTimeout(function () {
                    document.body.removeChild(a);
                }, 100);
            });
        }
    }

    downloadScale = () => {
        if (document.getElementsByClassName('scale-chart-container').length) {
            html2canvas(document.getElementsByClassName('scale-chart-container')[0], {
                scale: 3
            }).then((canvas) => {
                var a = document.createElement('a');

                a.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
                a.download = 'raport.jpg';
                document.body.appendChild(a);
                a.click();

                setTimeout(function () {
                    document.body.removeChild(a);
                }, 100);
            });
        }
    }

    downloadTableImage = () => {
        if (document.getElementsByClassName('pvtOutput').length) {
            html2canvas(document.getElementsByClassName('pvtOutput')[0], {
                scale: 1,
                allowTaint: true,
                logging: true,
            }).then((canvas) => {
                var a = document.createElement('a');

                a.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
                a.download = 'raport.jpg';
                a.click(function () {
                    document.body.removeChild(a);
                });
                document.body.appendChild(a);
                //  document.body.appendChild(canvas);

                a.click();

                /*setTimeout(function(){

                }, 100);  */
            });
        }
    }

    openReportDialog = () => {
        switch (this.state.values.report_type) {
            case 'pivot':
                return this.setState({ dialogPivot: true });
            case 'gis':
                return this.setState({ dialogGIS: true });
            case 'answers':
                return this.setState({ dialogAnswers: true });
            case 'choice_fixed':
                return this.setState({ dialogScale: true });
        }
    }

    closeScaleDialog = () => {
        this.setState({ dialogScale: false });
    }

    closePivotDialog = () => {
        this.setState({ dialogPivot: false });
    }

    addCell = (question, cells) => {
        if (!question) {
            this.setState({ cellPicker: false });
        } else {
            let questions = this.state.questions;

            let columnLabels = {};
            if (question.type === 'dynamic_table') {
                let { meta } = question.properties.table;
                Object.entries(meta).forEach(([cellName, cell]) => {
                    const [x, y] = jexcel.getIdFromColumnName(cellName, true);
                    const column = jexcel.getColumnName(x);
                    if (!y) { // column label
                        columnLabels[column] = (cell.config + '').replace(/<[^>]+>/g, '');
                    }
                });
            }

            if (Array.isArray(cells)) {
                cells.forEach(cell => {
                    let q = clone(question);
                    if (question.type === 'dynamic_table') {
                        cell.label = columnLabels[jexcel.getColumnName(cell.x)];
                    }
                    q.cell = cell;
                    questions.push(q);
                });
            } else {
                question.cell = cells ? cells : null;
                questions.push(question);
            }

            this.setState({ questions, cellPicker: false });
        }
    };

    addQuestions = questions => {
        if (!questions) {
            this.setState({ questionsPicker: false, sectionsPicker: false });
        } else {
            let newQuestions = [];
            questions.forEach(question => {
                if (question.type === 'static_table') {
                    let { meta } = question.properties.table;
                    // Object.keys(meta).sort().forEach(cellName => {
                    Object.entries(meta).forEach(([cellName, cell]) => {
                        switch (cell.type) {
                            case undefined:
                            case 'label':
                                return;
                        }
                        const cellQuestion = clone(question);
                        const [x, y] = jexcel.getIdFromColumnName(cellName, true);
                        cellQuestion.cell = { name: cellName, x, y, label: cell.label };
                        newQuestions.push(cellQuestion);
                    });
                } else if (question.type === 'dynamic_table') {
                    let { meta } = question.properties.table;
                    let columnLabels = {};
                    Object.entries(meta).forEach(([cellName, cell]) => {
                        const cellQuestion = clone(question);
                        const [x, y] = jexcel.getIdFromColumnName(cellName, true);
                        const column = jexcel.getColumnName(x);
                        if (!y) { // column label
                            columnLabels[column] = (cell.config + '').replace(/<[^>]+>/g, '');
                            return;
                        }
                        cellQuestion.cell = { column, x, label: columnLabels[column] };
                        newQuestions.push(cellQuestion);
                    });
                } else {
                    newQuestions.push(question);
                }
            });
            this.setState({ questions: this.state.questions.concat(newQuestions), questionsPicker: false, sectionsPicker: false });
        }
    };

    addSections = (sections) => {
        Axios.post('/data-reports/getQuestionsBySections', {
            section_ids: sections.map(section => section.id),
        }).then((response) => {
            // let currentQuestions = this.state.questions;
            //Object.assign(currentQuestions, response.data.list.map((v) => v.question));
            // this.setState({ questions: currentQuestions.concat(response.data.list.map((v) => v.question)) });
            this.addQuestions(response.data.list);
        }).catch(this.catchErrors);

        // this.setState({ sectionsPicker: false });
    }

    addPolls = (polls) => {
        let currentPolls = this.state.polls;
        this.setState({ polls: currentPolls.concat(polls), pollsPicker: false });
    }

    openQuestionsPicker = () => {
        this.setState({ questionsPicker: true });
    }

    openCellPicker = () => {
        this.setState({ cellPicker: true });
    };

    openSectionsPicker = () => {
        this.setState({ sectionsPicker: true });
    }

    openPollsPicker = () => {
        this.setState({ pollsPicker: true });
    }

    deleteQuestion = key => () => {

        let questions = this.state.questions;
        questions.splice(key, 1);

        this.setState({ questions });
    }

    deletePoll = key => () => {
        let polls = this.state.polls;
        polls.splice(key, 1);

        this.setState({ polls });
    }

    saveTemplate = () => {
        this.setState({ saveDialog: true });
    }

    confirmSaveTemplate = () => {
        let method, methodUrl;

        if (this.state.templateUpdate || this.state.templateID === "") {
            method = Axios.post;
            methodUrl = '/preset-report';
        } else {
            method = Axios.put;
            methodUrl = '/preset-report/' + this.state.templateID;
        }

        let additionalData = { gis: {}, pivot: {}, answers: {}, scale: {} };

        switch (this.state.values.report_type) {
            case 'gis':
                additionalData.gis = this.gisRef.current ? this.gisRef.current.state.values : {};
                break;
            case 'pivot':
                if (this.pivotRef.current) {
                    for (let valueName in this.pivotRef.current.state.values) {
                        let value = this.pivotRef.current.state.values[valueName];

                        if (['year', 'aggregatorName', 'colOrder', 'cols', 'derivedAttributes', 'hiddenAttributes',
                            'hiddenFromAggregators', 'hiddenFromDragDrop', 'menuLimit', 'rendererName',
                            'rowOrder', 'rows', 'sorters', 'tableOptions',
                            'unusedOrientationCutoff', 'vals', 'valueFilter'].includes(valueName)) {
                            additionalData.pivot[valueName] = value;
                        }
                    }
                    ;
                }
                break;
            case 'answers':
                additionalData.answers = this.answersRef.current ? this.answersRef.current.state.values : {};
                break;
            case 'choice_fixed':
                additionalData.scale = this.scaleRef.current ? this.scaleRef.current.state.values : {};
                break;
        }

        method(methodUrl, {
            packed: {
                baseValues: this.state.values,
                questions: this.state.questions,
                museums: this.state.museums,
                sections: this.state.sections,
                polls: this.state.polls,
                conditions: this.state.conditions,
                additionalData: additionalData
            },
            title: this.state.templateName
        }).then((response) => {
            this.setState({ templateID: response.data.object.id, templateName: response.data.object.title, saveDialog: false });
        }).catch(this.catchErrors);
    }

    getTemplates = (callback) => {
        Axios.get('/preset-report').then((response) => {
            this.setState({ templates: response.data.list }, () => {
                callback && callback();
            });
        }).catch(this.catchErrors);
    }

    onTemplateChange = (data, option) => {
        this.setState({ currentTemplate: option.props });
    }

    deleteTemplate = () => {
        Axios.delete('/preset-report/' + this.state.templateID).then((response) => {
            this.setState({ templateID: '', templateName: '', saveDialog: false }, () => {
                this.getTemplates();
            });
        }).catch(this.catchErrors);
    }

    getTemplateValues = (id) => {

        for (let i = 0; i < this.state.templates.length; i++) {
            if (this.state.templates[i].id === id) {
                //console.log(JSON.parse(this.state.templates[i].packed));
                return JSON.parse(this.state.templates[i].packed);
            }
        }
    }

    loadTemplate = () => {
        this.getTemplates(() => {
            this.setState({ loadDialog: true });
        });
    }

    confirmLoadTemplate = () => {
        if (this.state.currentTemplate.value) {
            var tplPacked = this.getTemplateValues(this.state.currentTemplate.value);

            this.setState({
                dialogPivot: Object.keys(tplPacked.additionalData.pivot).length ? true : false,
                dialogGIS: Object.keys(tplPacked.additionalData.gis).length ? true : false,
                dialogScale: Object.keys(tplPacked.additionalData.scale).length ? true : false,
                dialogAnswers: Object.keys(tplPacked.additionalData.answers).length ? true : false,

                templateID: this.state.currentTemplate.value,
                templateName: this.state.currentTemplate.children,
                loadDialog: false,
                values: tplPacked.baseValues,
                questions: tplPacked.questions,
                museums: tplPacked.museums,
                sections: tplPacked.sections,
                polls: tplPacked.polls,
                conditions: tplPacked.conditions,
                additionalData: tplPacked.additionalData,
            });
        }
    }

    openMuseumsPicker = () => {
        this.setState({ museumsPicker: true });
    }

    addMuseums = (museums) => {
        let currentMuseums = this.state.museums;
        this.setState({ museums: currentMuseums.concat(museums), museumsPicker: false });
    }

    deleteMuseum = (key) => () => {
        let museums = this.state.museums;
        museums.splice(key, 1);

        this.setState({ museums });
    }

    getYearsOption = () => {
        let data = [];
        this.years.forEach(year => {
            data.push({ value: year, text: year });
        });

        return data;
    }

    changeSettings = (name, value) => {
        let values = this.state.values;
        if (name === 'report_type') {
            let allowedTypes = [];
            switch (value) {
                case 'answers':
                case 'pivot':
                    allowedTypes = ['boolean', 'choice_single', 'choice_multiple', 'number', 'date', 'year', 'text', 'email', 'regexp', 'mask', 'open', 'static_table', 'dynamic_table'];
                    break;
                case 'gis':
                    allowedTypes = ['number'];
                    break;
                case 'choice_fixed':
                    allowedTypes = ['choice_fixed'];
                    break;
                default:
                    break;
            }
            values.question_type = values.question_type.filter(qt => allowedTypes.includes(qt));
        }

        this.setState({ values: dotSet(values, name, value) });
    }

    downloadXLSX = () => {
        let elt = document.getElementsByClassName('pvtTable')[0];
        if (elt) {
            let wb = XLSX.utils.table_to_book(elt, { sheet: "Raport" });

            XLSX.writeFile(wb, 'raport.xlsx');
        } else {
            alert('Musisz najpierw przełączyć widok w tryb tabeli');
        }
    }

    getQuestionFullName(question) {
        let name = question.name_string;
        if (!!question.cell) {
            if (!!question.cell.name) {
                name += ' [komórka ' + question.cell.name + ']';
            } else {
                name += ' [kolumna ' + question.cell.column + ']';
            }
            name += ' ' + (question.cell.label || '');
        }

        return name.trim();
    }

    render() {
        if (this.state.isLoading) {
            return <CircularProgress size={30} thickness={5}/>;
        }

        const { classes } = this.props;
        const { questions, values, museums, polls, conditions } = this.state;

        let questionTypes = [];
        if (values.report_type === 'pivot' || values.report_type === 'answers') {
            questionTypes = [
                { value: 'boolean', text: 'Odpowiedź TAK / NIE' },
                { value: 'choice_single', text: 'Jednokrotny wybór' },
                { value: 'choice_multiple', text: 'Wielokrotny wybór' },
                { value: 'number', text: 'Liczba' },
                { value: 'date', text: 'Data' },
                { value: 'year', text: 'Rok' },
                { value: 'text', text: 'Pole tekstowe' },
                { value: 'email', text: 'E-mail' },
                { value: 'regexp', text: 'RegExp' },
                { value: 'mask', text: 'Maska' },
                { value: 'open', text: 'Otwarte' },
                { value: 'static_table', text: 'Tabela statyczna' },
                { value: 'dynamic_table', text: 'Tabela dynamiczna' }
            ];
        } else if (values.report_type === 'gis') {
            questionTypes = [
                { value: 'number', text: 'Liczba' },
                // {value: 'text', text: 'Pole tekstowe'},
            ];
        } else if (values.report_type === 'choice_fixed') {
            questionTypes = [
                { value: 'choice_fixed', text: 'Skala' },
            ];
        }

        const fields = [
            {
                name: 'poll.type',
                label: 'Typ ankiety',
                type: 'multiselect',
                width: 400,
                options: [
                    {
                        value: 'branched',
                        text: 'Oddziałowa',
                    },
                    {
                        value: 'branch',
                        text: 'Dla oddziału',
                    },
                    {
                        value: 'branchless',
                        text: 'Bezoddziałowa',
                    },
                ],
            },
            {
                name: 'poll.state',
                label: 'Status ankiety',
                type: 'multiselect',
                // multiple: true,
                width: 400,
                options: [
                    {
                        value: 'accepted',
                        text: 'zaakceptowane',
                    },
                    {
                        value: 'not_accepted',
                        text: 'niezaakceptowane',
                    },
                ]
            },
            {
                name: 'report_type',
                label: 'Rodzaj raportu',
                type: 'select',
                options: [
                    { value: 'answers', text: 'Lista odpowiedzi' },
                    { value: 'pivot', text: 'Tabele przestawne' },
                    { value: 'gis', text: 'Mapy' },
                    { value: 'choice_fixed', text: 'Skala' }
                ]
            },
        ];

        fields.push(
            {
                text: 'TEREN DZIAŁANIA',
                type: 'info',
            },
            {
                type: 'custom',
                component: <>Adres do korespondencji</>,
            },
            { type: 'divider' },
            {
                name: 'data.contact_city',
                label: 'miejscowość',
                type: 'text',
                width: 416,
            },
            {
                name: 'data.contact_postcode',
                label: 'kod pocztowy',
                type: 'postcode',
                width: 100,
            },
            {
                name: 'data.contact_post',
                label: 'poczta',
                type: 'text',
                width: 258,
            },
            {
                name: 'data.contact_commune',
                label: 'gmina',
                type: 'text',
                width: 258,
            },
            {
                name: 'data.contact_county',
                label: 'powiat',
                type: 'text',
                width: 258,
            },
            {type: 'divider'},
            {
                name: 'data.contact_voivodeship',
                label: 'województwo',
                type: 'voivodeship',
                width: 258,
                multiple: true,
            },
            {
                type: 'custom',
                component: <TooltipIconButton title="Zaznacz wszystkie województwa" onClick={() => this.changeSettings('data.contact_voivodeship', Museum.voivodeships)}>
                    <CheckBoxIcon/>
                </TooltipIconButton>
            },
            {
                type: 'custom',
                component: <TooltipIconButton title="Odznacz wszystkie województwa" onClick={() => this.changeSettings('data.contact_voivodeship', [])}>
                    <CheckBoxOutlineBlankIcon/>
                </TooltipIconButton>
            },
            {type: 'divider'},
            {
                name: 'data.administrative_size',
                label: 'Wielkość jednostki administracyjnej',
                type: 'multiselect',
                // emptyValue: '- wybierz -',
                options: [
                    { value: 'powyżej 500 tys. mieszkańców' },
                    { value: 'powyżej 100 do 500 tys.' },
                    { value: 'powyżej 10 do 100 tys.' },
                    { value: 'do 10 tys.' },
                ],
                width: 290,
            },
            {
                name: 'data.territory',
                label: 'Teren prowadzenie działalności',
                type: 'multiselect',
                // emptyValue: '- wybierz -',
                options: [
                    { value: 'miejska' },
                    { value: 'wiejska' },
                ],
                width: 254,
            },
        );
        fields.push(
            {
                text: 'PROFIL DZIAŁANIA',
                type: 'info',
            },
            {
                name: 'museum.type',
                label: 'Rodzaj',
                type: 'multiselect',
                options: removeItemsFromArrayByAttribute(getSelectOptions(Museum.types), 'branch', 'value'),
                width: 240,
            },
            {
                name: 'data.outdoor',
                label: 'Muzeum na wolnym powietrzu',
                type: 'multiselect',
                options: [
                    { value: 'tak' },
                    { value: 'nie' },
                ],
                width: 240,
            },
            {
                name: 'data.scientific_type',
                label: 'Typ muzeum',
                type: 'multiselect',
                // emptyValue: '- wybierz -',
                options: [
                    { value: 'jednorodne' },
                    { value: 'interdyscyplinarne' },
                ],
                width: 250,
            },
        );
        // if (values.data && values.data['scientific_type'].includes('jednorodne')) {
        fields.push(
            {
                name: 'data.scientific_type_value',
                label: 'jakie? (tylko dla muzeów jednorodnych)',
                type: 'multiselect',
                // emptyValue: '- wybierz -',
                options: [
                    { value: 'sztuki' },
                    { value: 'archeologiczne' },
                    { value: 'etnograficzne i antropologiczne' },
                    { value: 'historyczne' },
                    { value: 'martyrologiczne' },
                    { value: 'historii naturalnej' },
                    { value: 'techniki i nauki' },
                    { value: 'specjalistyczne' },
                    { value: 'inne' },
                ],
                width: 316,
            },
        );
        // }
        fields.push(
            { type: 'divider' },
            {
                type: 'custom',
                component: <span className={classes.year}>Rok założenia</span>,
            },
            {
                name: 'data.year.from',
                label: 'od',
                type: 'number',
                width: 60,
            },
            {
                name: 'data.year.to',
                label: 'do',
                type: 'number',
                width: 60,
            },
        );
        fields.push(
            {
                text: 'PODSTAWA DZIAŁANIA/ORGANIZACJI',
                type: 'info',
            },
            {
                name: 'data.financing',
                label: 'Źródło finansowania',
                type: 'multiselect',
                // emptyValue: '- wybierz -',
                options: [
                    { value: 'publiczne' },
                    { value: 'prywatne' },
                    { value: 'publiczno-prywatne' },
                ],
                width: 272,
            },
            {
                name: 'data.register_entry',
                label: 'Wpis do Państwowego Rejestru Muzeów',
                type: 'multiselect',
                options: [
                    { value: 'tak' },
                    { value: 'nie' },
                ],
                width: 316,
            },
            { type: 'divider' },
            {
                name: 'data.organizational_form',
                label: 'Forma organizacyjna muzeum',
                type: 'multiselect',
                // emptyValue: '- wybierz -',
                options: [
                    { value: 'państwowe' },
                    { value: 'samorządowe' },
                    { value: 'muzea nieposiadające osobowości prawnej' },
                ],
                width: 560,
            },
            {
                name: 'data.co_lead',
                label: 'Współprowadzenie',
                type: 'multiselect',
                options: [
                    { value: 'tak' },
                    { value: 'nie' },
                ],
                width: 272,
            },
            {
                name: 'data.activity_document',
                label: 'Dokument stanowiący podstawę działalności muzeum',
                type: 'multiselect',
                // emptyValue: '- wybierz -',
                options: [
                    { value: 'statut' },
                    { value: 'regulamin' },
                    { value: 'inny' },
                ],
                width: 416,
            },
            {
                name: 'data.mkidn_statute',
                label: 'Uzgodnienie statutu lub regulaminu z MKiDN',
                type: 'multiselect',
                options: [
                    { value: 'tak' },
                    { value: 'nie' },
                ],
                width: 356,
            },
            {
                name: 'data.entity_type',
                label: 'Rodzaj podmiotu organizującego',
                type: 'multiselect',
                // emptyValue: '- wybierz -',
                options: [
                    { value: 'MKiDN' },
                    { value: 'pozostałe ministerstwa' },
                    { value: 'inne publiczne osoby prawne' },
                    { value: 'jednostka samorządu terytorialnego' },
                    { value: 'państwowa instytucja kultury' },
                    { value: 'samorządowa instytucja kultury' },
                    { value: 'fundacja' },
                    { value: 'stowarzyszenie' },
                    { value: 'jednostka kościelna lub wyznaniowa' },
                    { value: 'uczelnia publiczna' },
                    { value: 'uczelnia niepubliczna' },
                    { value: 'osoba fizyczna' },
                    { value: 'podmiot gospodarczy' },
                    { value: 'inna' },
                ],
                width: 290,
            },
        );

        fields.push(
            { type: 'divider' },
            {
                type: 'info',
                text: 'Czas',
            },
            { type: 'divider' },
            {
                name: 'poll.year',
                label: 'Okres za jaki została złożona ankieta',
                type: 'multiselect',
                multiple: true,
                width: 300,
                options: this.getYearsOption()
            },
            {
                type: 'custom',
                component: <TooltipIconButton
                    title="Zaznacz wszystkie lata"
                    onClick={() => this.changeSettings('poll.year', this.years)}
                    className={classes.selectAll}>
                    <CheckBoxIcon/>
                </TooltipIconButton>
            },
            {
                type: 'custom',
                component: <TooltipIconButton
                    title="Odznacz wszystkie lata"
                    onClick={() => this.changeSettings('poll.year', [])}
                    className={classes.selectAll}>
                    <CheckBoxOutlineBlankIcon/>
                </TooltipIconButton>
            },
            { type: 'divider' },
            {
                type: 'info',
                text: 'Zakres danych',
                style: { borderTop: '1px solid #ccc' },
            },
            { type: 'divider' },
            {
                name: 'question_type',
                label: 'Typ pytania',
                type: 'multiselect',
                multiple: true,
                width: 400,
                options: questionTypes,
            },
            {
                name: 'estimated_data',
                label: 'Dane szacunkowe',
                type: 'select',
                width: 400,
                options: [
                    { value: 0, text: 'Nie' },
                    { value: 1, text: 'Tak' },
                ],
            },
            { type: 'divider' },
            {
                type: 'custom',
                component: <div><br/>
                    <Button id="reports-add-questions" variant="contained" color="primary" onClick={this.openQuestionsPicker}><AddIcon/> Dodaj pytania</Button>
                    &nbsp;&nbsp;i/lub&nbsp;&nbsp;
                    <Button id="reports-add-cell" variant="contained" color="primary" onClick={this.openCellPicker}><AddIcon/> Dodaj komórkę/kolumnę</Button>
                    &nbsp;&nbsp;i/lub&nbsp;&nbsp;
                    <Button variant="contained" color="primary" onClick={this.openSectionsPicker}><AddIcon/> Dodaj pytania z ankiety / sekcji</Button>
                    {this.state.questionsPicker && (
                        <QuestionsPicker
                            url="/catalogs/questions"
                            dialogTitle="Dodawanie pytań do raportu"
                            questions={questions}
                            changeHandler={this.addQuestions}
                            types={getObjValuesFromArrayByAttribute(questionTypes, 'value')}
                            pollFilters={values.poll}
                            museumFilters={values.museum}
                            dataFilters={values.data}
                        />
                    )}
                    {this.state.cellPicker && (
                        <QuestionPicker
                            dialogTitle="Dodawanie komórki/kolumny do raportu"
                            url="/catalogs/table-questions"
                            changeHandler={this.addCell}
                            enableColumnPicker={true}
                            multiple={true}
                            pollFilters={values.poll}
                            museumFilters={values.museum}
                            dataFilters={values.data}
                        />
                    )}
                    {this.state.sectionsPicker && (
                        <PollSectionPicker
                            changeHandler={this.addSections}
                            pollFilters={values.poll}
                            museumFilters={values.museum}
                            dataFilters={values.data}
                        />
                    )}
                    <br/><br/>
                    {questions.map((question, key) => {
                        return <Chip
                            key={key}
                            label={this.getQuestionFullName(question)}
                            variant="outlined"
                            onDelete={this.deleteQuestion(key)}
                        />
                    })}
                    {questions.length > 1 && (
                        <div>
                            <TooltipIconButton title="Wyczyść filtrowane pytania" onClick={() => this.setState({ questions: [] })}>
                                <DeleteSweepIcon/>
                            </TooltipIconButton>
                        </div>
                    )}
                </div>
            },
            { type: 'divider', style: { borderBottom: '1px solid #ccc' } },
            {
                type: 'custom',
                component: <div><br/><Button variant="contained" color="primary" onClick={this.openPollsPicker}><AddIcon/> Filtruj ankiety</Button>
                    {this.state.pollsPicker && (
                        <PollPicker
                            polls={polls}
                            changeHandler={this.addPolls}
                            pollFilters={values.poll}
                            museumFilters={values.museum}
                            dataFilters={values.data}
                        />
                    )}
                    <br/><br/>{polls.map((poll, key) => {
                        return <Chip
                            key={key}
                            label={poll.name + ' [' + poll.type_string + ', ' + poll.year + ']'}
                            variant="outlined"
                            onDelete={this.deletePoll(key)}
                        />
                    })}
                    {polls.length > 1 && (
                        <div>
                            <TooltipIconButton title="Wyczyść filtrowane ankiety" onClick={() => this.setState({ polls: [] })}>
                                <DeleteSweepIcon/>
                            </TooltipIconButton>
                        </div>
                    )}
                </div>
            },
            { type: 'divider', style: { borderBottom: '1px solid #ccc' } },
            {
                type: 'custom',
                component: <div><br/>
                    <Button variant="contained" color="primary" onClick={this.openMuseumsPicker}>
                        <AddIcon/> Filtruj muzea
                    </Button>
                    {this.state.museumsPicker && (
                        <PollMuseumsPicker
                            museums={museums}
                            changeHandler={this.addMuseums}
                            pollFilters={values.poll}
                            museumFilters={values.museum}
                            dataFilters={values.data}
                        />
                    )}
                    <br/><br/>{museums.map((museum, key) => {
                        return <Chip
                            key={key}
                            label={museum.name}
                            variant="outlined"
                            onDelete={this.deleteMuseum(key)}
                        />
                    })}
                    {museums.length > 1 && (
                        <div>
                            <TooltipIconButton title="Wyczyść filtrowane muzea" onClick={() => this.setState({ museums: [] })}>
                                <DeleteSweepIcon/>
                            </TooltipIconButton>
                        </div>
                    )}
                </div>
            },
            { type: 'divider', style: { borderBottom: '1px solid #ccc' } },
            {
                type: 'custom',
                component: <div><br/>
                    <ReportsAnswerConditionsPicker
                        inline
                        grouping={false}
                        conditions={conditions}
                        changeHandler={conditions => this.setState({ conditions })}
                        pollFilters={values.poll}
                        museumFilters={values.museum}
                        dataFilters={values.data}
                    />
                    <br/><br/>
                </div>
            },
            { type: 'divider', style: { borderBottom: '1px solid #ccc' } },
        );

        let dialogContent;

        if (this.state.dialogPivot) {
            dialogContent = <Dialog classes={{ paper: classes.dialogPaper }} fullScreen={true} maxWidth={'lg'} fullWidth={true} open={this.state.dialogPivot}
                                    onClose={this.closePivotDialog}>
                <DialogContent className={classes.content}>
                    <PivotReport ref={this.pivotRef} params={this.state}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.saveTemplate} variant="contained" color="secondary">Zapisz szablon</Button>
                    <Button onClick={this.downloadTableImage} variant="contained" color="primary">Pobierz w formie JPG</Button>
                    <Button onClick={this.downloadXLSX} variant="contained" color="primary">Pobierz w formacie XLSX</Button>
                    <Button id="reports-close" onClick={this.closePivotDialog} variant="contained" color="secondary">Zamknij</Button>
                </DialogActions>
            </Dialog>
        } else if (this.state.dialogGIS) {
            dialogContent = <Dialog classes={{ paper: classes.dialogPaper }} fullScreen={true} maxWidth={'lg'} fullWidth={true} open={this.state.dialogGIS}
                                    onClose={this.closeGISDialog}>
                <DialogContent className={classes.content}>
                    <GISReport ref={this.gisRef} params={this.state}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.saveTemplate} variant="contained" color="secondary">Zapisz szablon</Button>
                    <Button onClick={this.downloadGIS} variant="contained" color="primary">Pobierz raport</Button>
                    <Button id="reports-close" onClick={this.closeGISDialog} variant="contained" color="secondary">Zamknij</Button>
                </DialogActions>
            </Dialog>
        } else if (this.state.dialogAnswers) {
            dialogContent = <Dialog classes={{ paper: classes.dialogPaper }} fullScreen={true} maxWidth={'lg'} fullWidth={true} open={this.state.dialogAnswers}
                                    onClose={this.closeAnswersDialog}>
                <DialogContent className={classes.content}>
                    <AnswersReport ref={this.answersRef} params={this.state}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.saveTemplate} variant="contained" color="secondary">Zapisz szablon</Button>
                    <Button id="reports-close" onClick={this.closeAnswersDialog} variant="contained" color="secondary">Zamknij</Button>
                </DialogActions>
            </Dialog>
        } else if (this.state.dialogScale) {
            dialogContent = <Dialog classes={{ paper: classes.dialogPaper }} fullScreen={true} maxWidth={'lg'} fullWidth={true} open={this.state.dialogScale}
                                    onClose={this.closeScaleDialog}>
                <DialogContent className={classes.content}>
                    <ScaleReport ref={this.scaleRef} params={this.state}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.saveTemplate} variant="contained" color="secondary">Zapisz szablon</Button>
                    <Button onClick={this.downloadScale} variant="contained" color="primary">Pobierz raport</Button>
                    <Button id="reports-close" onClick={this.closeScaleDialog} variant="contained" color="secondary">Zamknij</Button>
                </DialogActions>
            </Dialog>
        }

        return (
            <Paper className={classes.root}>
                {dialogContent}
                <NCForm
                    fields={fields}
                    values={values}
                    hideSubmitButton={true}
                    title="Wybierz rodzaj raportu"
                    onChange={this.changeSettings}
                />
                <br/>
                <Button id="reports-generate" onClick={this.openReportDialog} variant="contained" color="secondary"><ChartIcon/> Generuj raport</Button>&nbsp;

                <FabContainer>
                    <FabButton title="Wczytaj szablon" onClick={this.loadTemplate} color="default"><FolderOpenIcon/></FabButton>
                    <FabButton title="Zapisz szablon" onClick={this.saveTemplate} color="primary"><SaveIcon/></FabButton>
                    {this.state.templateID && <FabButton title="Usuń szablon" onClick={this.deleteTemplate} color="secondary"><DeleteIcon/></FabButton>}
                </FabContainer>

                <ConfirmDialog isContentText={false} confirmTitle={"Zapisz"} title={"Zapisywanie szablonu"} open={this.state.saveDialog}
                               onClose={() => {this.setState({ saveDialog: false });}} onConfirm={this.confirmSaveTemplate}>
                    <TextField
                        label={"Wprowadź nazwę szablonu dla skonfigurowanego raportu"}
                        defaultValue={this.state.templateName}
                        onBlur={(e) => {this.setState({ templateName: e.target.value })}}
                        style={{ width: 400 }}
                        margin="normal"
                    ></TextField>

                    {!!this.state.templateID && <FormControlLabel
                        style={{ marginRight: 30 }}
                        label={"Zapisz jako odrębny szablon"}
                        control={<Checkbox onChange={(e) => {this.setState({ templateUpdate: e.target.checked })}} value="1" checked={this.state.templateUpdate}/>}
                    />}
                </ConfirmDialog>


                <ConfirmDialog isContentText={false} confirmTitle={"Wczytaj"} title={"Wczytywanie szablonu"} open={this.state.loadDialog}
                               onClose={() => {this.setState({ loadDialog: false });}} onConfirm={this.confirmLoadTemplate}>
                    <Select style={{ width: 400 }} onChange={this.onTemplateChange} value={this.state.currentTemplate.value}>
                        {this.state.templates.map((option, k2) => {
                            return <MenuItem value={option.id} key={k2}>
                                {option.title}
                            </MenuItem>
                        })}
                    </Select>
                </ConfirmDialog>
            </Paper>
        );
    }
}

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    dialogPaper: {
        minHeight: '80vh',
    },
    content: {
        position: 'relative',
        overflowX: 'auto',
    },
    selectAll: {
        padding: 0,
        height: 20,
        alignSelf: 'center',
        marginLeft: theme.spacing(),
        marginTop: theme.spacing(),
    },
    year: {
        alignSelf: 'center',
        paddingTop: 10,
        paddingRight: theme.spacing(2),
    },
});

export default withStyles(styles)(DataReports);
