import React from "react";
import Axios from 'axios';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import BugReportIcon from '@material-ui/icons/BugReport';
import DeleteIcon from '@material-ui/icons/Delete';

import { areObjectsEqual, clone, coalesceString, dotGet, dotSet } from "./Helpers";
import { Typography } from "@material-ui/core";
import User from "../auth/User";
import FabButton from "./FabButton";
import BaseComponent from "./BaseComponent";

class DataComponent extends BaseComponent {
    tableColumns = {};
    filtersString = '';
    query = {};
    queryFilters = {};
    resolvedUrl = '';

    tableRef = React.createRef();
    table = [];

    state = {
        deleteDialog: false,
        isLoading: true,
        data: {},
        values: {},
        errors: {},
    };

    getObjectId() {}

    constructor(props){
        super(props);

        this.fetchTable = this.fetchTable.bind(this); // has to be bound this way
        this.getObjectId = this.getObjectId.bind(this);
    }

    componentDidMount() {
        this.resolvedUrl = typeof this.getUrl === 'function' ? this.getUrl() : this.url;
        if (this.resolvedUrl) {
            Axios.get(this.resolvedUrl).then((response) => {
                this.setStateData(response.data, { isLoading: false });
            }).catch(this.catchErrors);
        }
    }

    /**
     * Prevents re-rendering if only state values have changed.
     * Boosts performance when values are propagated to parent class and come back as new props anyway
     *
     * @param nextProps
     * @param nextState
     * @returns {boolean}
     */
    shouldComponentUpdateHandler = (nextProps, nextState) => {
        if (!areObjectsEqual(this.props, nextProps)) {
            return true;
        }

        let currentState = clone(this.state);
        delete currentState['values'];

        let newState = clone(nextState);
        delete newState['values'];

        if (!areObjectsEqual(currentState, newState)) {
            return true;
        }

        return false;
    };

    reloadStateData = (data, state = {}) => {
        this.setStateData(data, state);
        this.forceUpdate();
    };

    setStateData = (data, state = {}) => {
        let newState = Object.assign({},
            this.processData(data),
            { values: this.processValues(data) },
            state
        );

        this.setState(newState, this.onDataLoaded);
    };

    onDataLoaded() {};

    processData = (data) => {
        return { data };
    };

    processValues = (data) => {
        return clone(data.object);
    };

    changeHandler = (name, value) => {
        this.setState({ values: dotSet(this.state.values, name, value) });
    };

    reloadTable = () => {
        const { current } = this.tableRef;
        if (!current) {
            console.log('Missing tableRef!');
            return;
        }
        if (current.state.query.filters) {
            // workaround for missing values in inputs:
            current.state.query.filters.forEach((data, key) => {
                current.dataManager.changeFilterValue(dotGet(data, 'column.tableData.id'), dotGet(data, 'column.tableData.filterValue'));
            });
        }
        // reload table
        current.onQueryChange();
    };

    getTableColumns = (columns) => {
        return Object.entries(columns || this.tableColumns).map(([key, value]) => {
            return Object.assign({ field: key }, typeof value === 'string' ? { title: value } : value);
        });
    };

    addDeletedAtColumn = () => {
        if (User.isSuperadmin()) {
            this.tableColumns['deleted_at'] = {
                title: 'Usunięty?',
                column: 'deleted_at',
                field: 'function',
                callback: rowData => rowData.deleted_at,
                lookup: { 0: 'Nie', 1: 'Tak' },
                defaultFilter: ['0'],
            };
        }
    };

    getTableData = () => {
        return this.state.data.table || [];
    };

    hasObject = () => {
        return !!this.state.data.object || false;
    };

    getObject = (attributePath) => {
        const object = this.state.data.object || {};
        if (attributePath) {
            return dotGet(object, attributePath);
        }

        return object;
    };

    getValues = (path) => {
        const values = this.state.values;
        if (path) {
            return dotGet(values, path);
        }

        return values;
    };

    hasParentObject = () => {
        return !!this.state.data.parent || false;
    };

    getParentObject = (attributePath) => {
        const object = this.state.data.parent || {};
        if (attributePath) {
            return dotGet(object, attributePath);
        }

        return object;
    };

    getTitle = () => {
        return this.hasObject() ? "Edycja: " + coalesceString(this.getObject('name'), this.getObject('title')) : 'Dodaj';
    };

    getErrors = (path) => {
        const errors = this.state.errors;
        if (path) {
            return dotGet(errors, path);
        }

        return errors;
    };

    catchErrors = (error) => {
        console.error(error);
        if (error.response && error.response.data && error.response.data.errors) {
            this.setState({
                errors: error.response.data.errors,
                isLoading: false,
            });
        } else {
            this.setState({
                isLoading: this.constructor.name,
            });
        }
    };

    loadTable = (data, columns = this.tableColumns) => {
        this.table = [];

        data.forEach((model, key) => {
            let row = model;
            for (let c in columns) {
                let v = undefined;
                switch (true) {
                    case undefined !== (v = dotGet(model, c + '_string')):
                        break;
                    case undefined !== (v = dotGet(model, c)):
                        break;
                    default:
                        v = '-';
                }
                row[c] = v === undefined ? '-' : v;
            }
            this.table.push(row);
        });

        return this.table;
    };

    loadTableData = url => query => new Promise((resolve, reject) => {
        query = clone(query); // clone query to avoid object/array reference issues
        query.page++; // Eloquent first page is 1
        if (query.orderBy) {
            query.orderBy = query.orderBy.column || query.orderBy.field;
        }
        if (query.filters) {
            let filters = {};
            query.filters.forEach(data => {
                filters[data.column.column || data.column.field] = data.value;
            });
            query.filters = filters;
        }

        const queryFilters = JSON.stringify(query.filters);
        if (this.filtersString !== queryFilters) {
            query.page = 1; // go to first page after using filters
        }
        this.query = query;
        this.filtersString = queryFilters;
        this.queryFilters = query.filters;

        this.fetchTable(url, query, resolve);
    });

    fetchTable(url, query, resolve, counter = 0) {
        Axios.get(url, { params: query }).then(response => {
            const { table } = response.data;
            let { current_page, last_page, total } = table;

            if (counter > 3) {
                throw new Error('Infinite loop?');
            }

            if (current_page < 1) {
                query.page = 1;
            }
            // invalid page will result in empty/invalid data
            if (current_page > last_page) {
                query.page = last_page;
                this.fetchTable(url, query, resolve, ++counter);
                return;
            }

            resolve({
                data: this.loadTable(table.data, this.tableColumns),
                page: current_page - 1, // MaterialTable first page is 0
                totalCount: total,
            });
        }).catch(this.catchErrors);
    };

    closeDeleteDialog = () => {
        this.setState({ deleteDialog: false });
    };

    openDeleteDialog = () => {
        this.setState({ deleteDialog: true });
    };

    deleteObject = (redirectUrl) => event => {
        Axios.delete(this.url).then(response => {
            this.props.history.push(redirectUrl);
        }).catch(this.catchErrors);
    };

    renderDeleteButton = () => {
        if (this.hasObject()) {
            // if (this.getObject('deleted_at')) {
            //     return <FabButton title="Przywróć" color="secondary" onClick={() => this.setState({ restoreDialog: true })}><RestoreFromTrashIcon/></FabButton>;
            // } else {
                return <FabButton title="Usuń" onClick={this.openDeleteDialog} color="secondary"><DeleteIcon/></FabButton>;
            // }
        }

        return '';
    };

    showLoader = () => {
        if (typeof this.state.isLoading !== 'boolean') {
            return <div style={{ padding: 10 }}>
                <BugReportIcon color="secondary" fontSize="large" style={{ verticalAlign: 'middle' }}/>
                <Typography component="span" color="secondary" style={{ verticalAlign: 'sub' }}>
                    Error: {this.resolvedUrl} {this.state.isLoading}
                </Typography>
            </div>;
        }

        return <CircularProgress size={30} thickness={5}/>;
    };
}

export default DataComponent;
