import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import NCForm from '../nc-form/NCForm';

import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CallSplitIcon from '@material-ui/icons/CallSplit';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Axios from 'axios';

import { DialogTitle, DialogContent } from "../shared/Dialog";
import DataComponent from "../shared/DataComponent";
import DataTable from "../shared/DataTable";

import { FormHelperText, IconButton } from "@material-ui/core";
import PropTypes from "prop-types";

import User from "../auth/User";
import { clone } from "../shared/Helpers";
import FabButton from "../shared/FabButton";
import ConfirmDialog from "../shared/ConfirmDialog";
import InfoIcon from "@material-ui/icons/Info";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    button: {
        marginRight: 20,
    },
});

class MuseumTabBranches extends DataComponent {
    museum_id = this.props.museum_id;
    url = '/museums/' + this.museum_id + '/branches';
    tableColumns = {
        idx: 'ID',
        name: 'Nazwa oddziału',
        'data.email': 'Email',
        'data.phone_nb': 'Numer telefonu',
        created_at: "Data utworzenia",
    };

    constructor(props) {
        super(props);

        this.state.addDialog = false;
        this.state.editBranchId = false;
        this.state.splitBranchId = false;
        this.state.deleteBranchId = false;

        if (User.hasPermissionTo('edit-museum-branches')) {
            props.buttons.push(<FabButton key="addBranch" onClick={this.openAddDialog} color="primary" title="Dodaj oddział" className={props.classes.button}>
                <AddIcon/>
            </FabButton>);
        }

        this.addDeletedAtColumn();
    }

    splitBranch = () => {
        Axios.put(this.url + '/split/' + this.state.splitBranchId).then(response => {
            this.setState({
                splitBranchId: false,
                data: response.data,
                values: {},
                errors: {},
            });
        }).then(this.reloadTable).catch(this.catchErrors);
    };

    saveObject = () => {
        const { editBranchId, values } = this.state;
        if (editBranchId) {
            Axios.put(this.url + '/' + editBranchId, values).then(response => {
                this.setState({
                    editBranchId: false,
                    data: response.data,
                    values: {},
                    errors: {},
                });
            }).then(this.reloadTable).catch(this.catchErrors);
        } else {
            Axios.post(this.url, values).then(response => {
                this.setState({
                    addDialog: false,
                    data: response.data,
                    values: {},
                    errors: {},
                });
            }).then(this.reloadTable).catch(this.catchErrors);
        }
    };

    deleteObject = () => {
        Axios.delete(this.url, { data: { branch_id: this.state.deleteBranchId } }).then(response => {
            this.setStateData(response.data, {
                isLoading: false,
                editBranchId: false,
                splitBranchId: false,
                deleteBranchId: false,
            });
        }).then(this.reloadTable).catch(this.catchErrors);
    };

    openAddDialog = event => {
        this.setState({
            addDialog: true,
            dialogTitle: 'Dodaj oddział',
            saveLabel: 'Dodaj',
        });
    };

    openDeleteDialog = (branch_id) => event => {
        this.setState({ deleteBranchId: branch_id });
    };

    closeDeleteDialog = () => {
        this.setState({ deleteBranchId: false });
    };

    closeDialogs = () => {
        this.setState({ deleteBranchId: false, editBranchId: false, splitBranchId: false, addDialog: false, values: {}, errors: {} });
    };

    render() {
        if (this.state.isLoading) {
            return this.showLoader();
        }
        const { values, addDialog, editBranchId, splitBranchId, deleteBranchId, dialogTitle, saveLabel } = this.state;

        const fields = [
            {
                name: 'name',
                type: 'text',
                label: 'Nazwa oddziału',
                width: 816,
                disabled: !User.hasPermissionTo('change-branch-name'),
            },
            { type: 'divider' },
        ];
        if (!User.hasAllPermissions('change-museum-name', 'change-museum-type')) {
            fields.push({
                type: 'custom',
                component: <Box display="flex">
                    <Box><InfoIcon color="primary" style={{ marginRight: 6 }}/></Box>
                    <Box><FormHelperText>W celu zmiany nazwy oddziału skontaktuj się z administratorem systemu wysyłając maila na adres: <a
                        href="mailto:wsparcie.statystyka@nimoz.pl">wsparcie.statystyka@nimoz.pl</a></FormHelperText></Box>
                </Box>
            });
        }

        if (User.hasPermissionTo('change-museum-internal-data')) {
            fields.push(
                {
                    label: 'ID (zgodne z numeracją NIMOZ)',
                    name: 'nimoz_id',
                    type: 'text',
                    width: 250,
                },
            );
        }

        fields.push(
            {
                text: 'Dane teleadresowe muzeum wynikające z procedury uzgadniania dokumentów z MKiDN',
                type: 'info',
            },
            {
                label: 'ulica',
                name: 'data.street',
                type: 'text',
                width: 300,
            },
            {
                label: 'numer domu',
                name: 'data.building_nb',
                type: 'text',
                width: 100,
            },
            {
                label: 'numer lokalu',
                name: 'data.local_nb',
                type: 'text',
                width: 100,
            },
            { type: 'divider' },
            {
                label: 'miejscowość',
                name: 'data.city',
                type: 'text',
                width: 416,
            },
            {
                label: 'kod pocztowy',
                name: 'data.postcode',
                type: 'postcode',
                width: 100,
            },
            { type: 'divider' },
            {
                label: 'poczta',
                name: 'data.post',
                type: 'text',
                width: 258,
            },
            {
                label: 'gmina',
                name: 'data.commune',
                type: 'text',
                width: 258,
            },
            { type: 'divider' },
            {
                label: 'powiat',
                name: 'data.county',
                type: 'text',
                width: 258,
            },
            {
                label: 'województwo',
                name: 'data.voivodeship',
                type: 'voivodeship',
                width: 258,
            },
            { type: 'divider' },
            {
                label: 'nr telefonu',
                name: 'data.phone_nb',
                type: 'phone',
                width: 100,
            },
            {
                label: 'e-mail',
                name: 'data.email',
                type: 'email',
                width: 416,
            },
        );

        fields.push(
            {
                text: 'Adres do korespondencji',
                type: 'info',
            },
            {
                label: 'ulica',
                name: 'data.contact_street',
                type: 'text',
                width: 300,
            },
            {
                label: 'numer domu',
                name: 'data.contact_building_nb',
                type: 'text',
                width: 100,
            },
            {
                label: 'numer lokalu',
                name: 'data.contact_local_nb',
                type: 'text',
                width: 100,
            },
            { type: 'divider' },
            {
                label: 'miejscowość',
                name: 'data.contact_city',
                type: 'text',
                width: 416,
            },
            {
                label: 'kod pocztowy',
                name: 'data.contact_postcode',
                type: 'postcode',
                width: 100,
            },
            { type: 'divider' },
            {
                label: 'poczta',
                name: 'data.contact_post',
                type: 'text',
                width: 258,
            },
            {
                label: 'gmina',
                name: 'data.contact_commune',
                type: 'text',
                width: 258,
            },
            { type: 'divider' },
            {
                label: 'powiat',
                name: 'data.contact_county',
                type: 'text',
                width: 258,
            },
            {
                label: 'województwo',
                name: 'data.contact_voivodeship',
                type: 'voivodeship',
                width: 258,
            },
            { type: 'divider' },
            {
                label: 'nr telefonu',
                name: 'data.contact_phone_nb',
                type: 'phone',
                width: 100,
            },
            {
                label: 'e-mail',
                name: 'data.contact_email',
                type: 'email',
                width: 416,
            },
            { type: 'hr' },
        );
        fields.push(
            {
                label: 'Strona www muzeum',
                name: 'data.www',
                type: 'text',
                width: 416
            },
            {
                label: 'Adres profilu na Facebooku (opcjonalnie)',
                name: 'data.facebook',
                type: 'text',
                width: 416,
            },
            { type: 'divider' },
        );
        fields.push(
            {
                label: 'Rok założenia oddziału',
                name: 'data.year',
                type: 'year',
                width: 180,
            },
            { type: 'divider' },
            {
                label: 'Wielkość jednostki administracyjnej',
                name: 'data.administrative_size',
                type: 'select',
                // emptyValue: '- wybierz -',
                options: [
                    { value: 'powyżej 500 tys. mieszkańców' },
                    { value: 'powyżej 100 do 500 tys.' },
                    { value: 'powyżej 10 do 100 tys.' },
                    { value: 'do 10 tys.' },
                ],
                width: 290,
            },
            {
                label: 'Teren prowadzenie działalności',
                name: 'data.territory',
                type: 'select',
                // emptyValue: '- wybierz -',
                options: [
                    { value: 'miejska' },
                    { value: 'wiejska' },
                ],
                width: 254,
            },
            { type: 'divider' },
        );
        fields.push(
            {
                label: 'Muzeum na wolnym powietrzu',
                name: 'data.outdoor',
                type: 'boolean_select',
                width: 316,
            },
            {
                label: 'Typ muzeum',
                name: 'data.scientific_type',
                type: 'select',
                // emptyValue: '- wybierz -',
                options: [
                    { value: 'jednorodne' },
                    { value: 'interdyscyplinarne' },
                ],
                width: 316,
            },
        );
        if (this.state.values.data && this.state.values.data['scientific_type'] === 'jednorodne') {
            fields.push(
                {
                    label: 'Jakie?',
                    name: 'data.scientific_type_value',
                    type: 'select',
                    // emptyValue: '- wybierz -',
                    options: [
                        { value: 'sztuki' },
                        { value: 'archeologiczne' },
                        { value: 'etnograficzne i antropologiczne' },
                        { value: 'historyczne' },
                        { value: 'martyrologiczne' },
                        { value: 'historii naturalnej' },
                        { value: 'techniki i nauki' },
                        { value: 'specjalistyczne' },
                        { value: 'inne' },
                    ],
                    width: 316,
                },
            );
        }
        fields.push(
            { type: 'divider' },
            {
                label: 'Uwagi',
                name: 'data.comments',
                type: 'textarea',
                width: 647,
            },
            { type: 'divider' },
        );

        if (editBranchId) {
            fields.push(
                {
                    label: 'Data aktualizacji rekordu',
                    name: 'updated_at',
                    type: 'text',
                    disabled: true,
                    width: 180,
                },
                {
                    label: 'Zaktualizował(a)',
                    name: 'updated_by',
                    type: 'text',
                    disabled: true,
                    width: 451,
                },
                { type: 'divider' },
            );
        }

        let title = "Lista oddziałów";
        if (!User.hasPermissionTo('edit-museum-branches')) {
            title = <Box display="flex" alignItems="center">
                <Box style={{ marginRight: 20 }}><Typography variant="h6">Lista oddziałów</Typography></Box>
                <Box><InfoIcon color="primary" style={{ marginRight: 6, marginTop: 6 }}/></Box>
                <Box><FormHelperText>W celu dodania oddziału skontaktuj się z administratorem systemu wysyłając maila na adres: <a
                    href="mailto:wsparcie.statystyka@nimoz.pl">wsparcie.statystyka@nimoz.pl</a></FormHelperText></Box>
            </Box>
        }

        return (<div>
            <DataTable
                tableRef={this.tableRef}
                storageKey={this.constructor.name}
                title={title}
                columns={this.getTableColumns()}
                data={this.loadTableData(this.url)}
                actions={[
                    rowData => User.hasPermissionTo('edit-museum-branches') ? ({
                        tooltip: 'Odłącz',
                        icon: CallSplitIcon,
                        onClick: (event, data) => {
                            this.setState({ splitBranchId: data.id });
                        },
                    }) : '',
                    rowData => User.hasPermissionTo('edit-museum-branches') || (
                        User.hasPermissionTo('edit-museum-branch') && rowData.id === User.getMuseumId()
                    ) || User.hasPermissionTo('update-museum-branch') ? ({
                        tooltip: 'Edytuj',
                        icon: EditIcon,
                        onClick: (event, data) => {
                            this.setState({
                                editBranchId: data.id,
                                values: clone(data),
                                dialogTitle: 'Edycja oddziału',
                                saveLabel: 'Zapisz',
                            });
                        },
                    }) : '',
                ]}
            />

            <Dialog open={!!(addDialog || editBranchId)} onClose={this.closeDialogs} maxWidth="md">
                <DialogTitle onClose={this.closeDialogs}>{dialogTitle} </DialogTitle>
                <DialogContent>
                    <NCForm fields={fields}
                            values={values}
                            errors={this.getErrors()}
                            onChange={this.changeHandler}
                            hideSubmitButton
                    />
                    <DialogActions>
                        {User.hasPermissionTo('edit-museum-branches') && values.id &&
                        <IconButton color="secondary" onClick={this.openDeleteDialog(editBranchId)}>
                            <DeleteIcon/>
                        </IconButton>
                        }
                        <Button variant="outlined" onClick={this.closeDialogs} color="default">Anuluj</Button>
                        <Button variant="contained" onClick={this.saveObject} color="primary">{saveLabel}</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>

            <ConfirmDialog title="Oddzielanie oddziału" open={!!splitBranchId} onClose={this.closeDialogs} onConfirm={this.splitBranch}>
                Czy na pewno chcesz oddzielić ten oddział?
            </ConfirmDialog>

            <ConfirmDialog open={!!deleteBranchId} onClose={this.closeDeleteDialog} onConfirm={this.deleteObject}>
                Czy na pewno chcesz usunąć ten oddział?<br/>
                Tylko administrator będzie miał możliwość przywrócenia usuniętego oddziału.
            </ConfirmDialog>
        </div>);
    }
}

MuseumTabBranches.propTypes = {
    addNew: PropTypes.bool,
    buttons: PropTypes.array,
    museum_id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]).isRequired,
};
MuseumTabBranches.defaultProps = {
    addNew: false,
    buttons: [],
};

export default withStyles(styles)(MuseumTabBranches);
