import React from 'react';
import { withRouter, Redirect } from "react-router";
import { Route } from "react-router-dom";
import PropTypes from 'prop-types';
import withUser from './components/auth/withUser';
// import LazyLoader from "./components/shared/LazyLoader";

import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import User from './components/auth/User';
import PasswordReset from './components/auth/PasswordReset';
import Login from './components/auth/Login';
import Loader from './components/shared/Loader';
import AppMenu from './components/shared/AppMenu';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { pl as dateFnsLocalePl } from 'date-fns/locale'
import './App.css';

import AllPollsList from './components/polls/AllPollsList';
import PollList from './components/polls/PollList';
import PollInstancesList from './components/polls/PollInstancesList';
import PollEdit from './components/polls/PollEdit';

import UserList from './components/users/UserList';
import UserEdit from './components/users/UserEdit';

import RoleList from './components/users/RoleList';
import RoleEdit from './components/users/RoleEdit';

import CatalogList from './components/questions/CatalogList';
import CatalogEdit from './components/questions/CatalogEdit';

import QuestionList from './components/questions/QuestionList';
import QuestionEdit from './components/questions/QuestionEdit';
import QuestionPolls from './components/questions/QuestionPolls';

import MuseumEdit from './components/museums/MuseumEdit';
import MuseumList from './components/museums/MuseumList';

import MuseumPolls from './components/museum-polls/MuseumPollList';
import MuseumViewPoll from './components/museum-polls/MuseumViewPoll';

import SystemReports from './components/reports/SystemReports';
import DataReports from './components/reports/DataReports';
import AnswersByMuseums from './components/reports/AnswersByMuseums';

import MuseumManager from "./components/museums/MuseumManager";
import ProfileEdit from "./components/user/ProfileEdit";
import UserProjects from './components/user/UserProjects';
import NotificationList from './components/notifications/NotificationList';
import UserNotificationList from './components/notifications/UserNotificationList';

import Dashboard from './components/dashboard/Dashboard';
import ProjectList from './components/projects/ProjectList';
import ProjectEdit from './components/projects/ProjectEdit';

import SettingsEdit from "./components/settings/SettingsEdit";
import SettingsList from "./components/settings/SettingsList";
import BaseComponent from "./components/shared/BaseComponent";
import { ErrorContextProvider } from "./components/shared/ErrorContext";

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
});

class App extends BaseComponent {
    state = {
        isLoggedIn: User.isLoggedIn(),
    };

    handleLogout = () => {
        User.logOut();
        this.setState({ isLoggedIn: false }, () => {
            this.props.history.push('/');
        });
    };

    render() {
        const { classes } = this.props;

        return (
            <ErrorContextProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={dateFnsLocalePl}>
                    <Loader/>
                    <div className={classes.root} style={{ paddingBottom: User.isLoggedIn() ? 70 : 0 }}>
                        <Route exact path="/password-reset/:token" component={PasswordReset}/>
                        <Login isLoggedIn={this.state.isLoggedIn}>
                            <CssBaseline/>
                            <AppMenu logoutFunction={this.handleLogout}/>

                            <main className={classes.content}>
                                <div className={classes.toolbar}/>

                                <Route exact path="/" component={withUser(Dashboard)}/>
                                <Route exact path="/profile" component={withUser(ProfileEdit)}/>

                                <Route exact path="/settings" component={withUser(SettingsList)}/>
                                <Route exact path="/settings/:setting_name" component={withUser(SettingsEdit)}/>

                                <Route exact path="/notifications"><Redirect to="/notifications/list"/></Route>
                                <Route exact path="/notifications/list" component={withUser(UserNotificationList)}/>
                                <Route exact path="/notifications/all" component={withUser(NotificationList)}/>

                                <Route exact path="/users"><Redirect to="/users/list"/></Route>
                                <Route exact path="/users/list" component={withUser(UserList)}/>
                                <Route exact path="/users/list/add" component={withUser(UserEdit)}/>
                                <Route exact path="/users/list/:user_id/edit" component={withUser(UserEdit)}/>

                                <Route exact path="/users/roles" component={withUser(RoleList)}/>
                                <Route exact path="/users/roles/add" component={withUser(RoleEdit)}/>
                                <Route exact path="/users/roles/:role_id/edit" component={withUser(RoleEdit)}/>

                                <Route exact path="/my-projects" component={withUser(UserProjects)}/>
                                <Route exact path="/polls" component={withUser(AllPollsList)}/>

                                <Route exact path="/projects" component={withUser(ProjectList)}/>
                                <Route exact path="/projects/add" component={withUser(ProjectEdit)}/>
                                <Route exact path="/projects/:project_id/edit" component={withUser(ProjectEdit)}/>

                                <Route exact path="/projects/:project_id/polls" component={withUser(PollList)}/>
                                <Route exact path="/projects/:project_id/polls/add" component={withUser(PollEdit)}/>
                                <Route exact path="/projects/:project_id/polls/:poll_id/edit" component={withUser(PollEdit)}/>
                                <Route exact path="/projects/:project_id/polls/:poll_id/list" component={withUser(PollInstancesList)}/>

                                <Route exact path="/catalogs"><Redirect to="/catalogs/list"/></Route>
                                <Route exact path="/catalogs/list" component={withUser(CatalogList)}/>
                                <Route exact path="/catalogs/list/add" component={withUser(CatalogEdit)}/>
                                <Route exact path="/catalogs/list/:parent_id/add" component={withUser(CatalogEdit)}/>
                                <Route exact path="/catalogs/list/:catalog_id/edit" component={withUser(CatalogEdit)}/>

                                <Route exact path="/catalogs/questions" component={withUser(QuestionList)}/>
                                <Route exact path="/catalogs/questions/:question_id/edit" component={withUser(QuestionEdit)}/>
                                <Route exact path="/catalogs/questions/:question_id/polls" component={withUser(QuestionPolls)}/>
                                <Route exact path="/catalogs/questions/:question_id/polls/:poll_id/list" component={withUser(PollInstancesList)}/>

                                <Route exact path="/catalogs/list/:catalog_id/questions" component={withUser(QuestionList)}/>
                                <Route exact path="/catalogs/list/:catalog_id/questions/add" component={withUser(QuestionEdit)}/>
                                <Route exact path="/catalogs/list/:catalog_id/questions/:question_id/edit" component={withUser(QuestionEdit)}/>
                                <Route exact path="/catalogs/list/:catalog_id/questions/:question_id/polls" component={withUser(QuestionPolls)}/>

                                <Route exact path="/museums" component={withUser(MuseumList)}/>
                                <Route exact path="/museums/add" component={withUser(MuseumEdit)}/>
                                <Route exact path="/museums/:museum_id/edit" component={withUser(MuseumEdit)}/>

                                <Route exact path="/institution-manager" component={withUser(MuseumManager)}/>

                                <Route exact path="/museum-polls" component={withUser(MuseumPolls)}/>
                                <Route exact path="/museum-polls/:id" component={withUser(MuseumViewPoll)}/>

                                <Route exact path="/reports/system" component={withUser(SystemReports)}/>
                                <Route exact path="/reports/data" component={withUser(DataReports)}/>
                                <Route exact path="/reports/answers-by-museums" component={withUser(AnswersByMuseums)}/>
                            </main>
                        </Login>
                    </div>
                </MuiPickersUtilsProvider>
            </ErrorContextProvider>
        );
    }
}

App.propTypes = {
    classes: PropTypes.object.isRequired,
    container: PropTypes.object,
    theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(withRouter(App));
