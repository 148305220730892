import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import NCForm from '../nc-form/NCForm';
import Axios from 'axios';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import User from "../auth/User";
import DataComponent from "../shared/DataComponent";
import FabContainer from "../shared/FabContainer";
import PaperRoot from "../shared/PaperRoot";
import FabButton, { FabLink } from "../shared/FabButton";
import Typography from "@material-ui/core/Typography";

class SettingsEdit extends DataComponent {
    static permissions = ['view-settings', 'edit-settings'];
    setting_name = this.props.match.params.setting_name;
    url = '/settings/' + this.setting_name;

    saveObject = () => {
        Axios.put(this.url, this.getValues()).then(response => {
            this.props.history.push('/settings');
        }).catch(this.catchErrors);
    };

    getTitle = () => {
        return this.hasObject() ? "Edycja ustawienia: " + this.getObject('title') : 'Dodaj ustawienie';
    };

    getVariables = () => {
        let variables = [];
        switch (this.getObject('name')) {
            case 'email_account_created':
                variables.push(
                    '{imie_nazwisko}',
                    '{imie}',
                    '{nazwisko}',
                    '{email}',
                    '{haslo}',
                    '{url}',
                );
                break;
            case 'email_password_reset':
                variables.push(
                    '{imie_nazwisko}',
                    '{imie}',
                    '{nazwisko}',
                    '{email}',
                    '{url}',
                );
                break;
            case 'email_poll_notification':
                variables.push(
                    '{imie_nazwisko}',
                    '{imie}',
                    '{nazwisko}',
                    '{email}',
                    '{tresc_powiadomienia}',
                    '{url}',
                );
                break;
            case 'email_poll_started':
                variables.push(
                    '{imie_nazwisko}',
                    '{imie}',
                    '{nazwisko}',
                    '{email}',
                    '{nazwa_ankiety}',
                    '{url}',
                );
                break;
            case 'email_unregistered':
                variables.push(
                    '{niezarejestrowane_nazwa}',
                    '{niezarejestrowane_email}',
                    '{imie_nazwisko_wlasciciela}',
                    '{imie_wlasciciela}',
                    '{nazwisko_wlasciciela}',
                    '{email_wlasciciela}',
                    '{nazwa_ankiety}',
                    '{url}',
                );
                break;
            case 'email_user_notification':
                variables.push(
                    '{imie_nazwisko}',
                    '{imie}',
                    '{nazwisko}',
                    '{email}',
                    '{tresc_powiadomienia}',
                    '{url}',
                );
                break;
            case 'notification_poll_started':
                variables.push(
                    '{nazwa_muzeum}',
                    '{nazwa_ankiety}',
                );
                break;
            case 'notification_poll_filling':
                variables.push(
                    '{nazwa_muzeum}',
                    '{nazwa_ankiety}',
                    '{imie_nazwisko}',
                    '{imie}',
                    '{nazwisko}',
                    '{email}',
                );
                break;
            case 'notification_admin_updated_question':
                variables.push(
                    '{nazwa_muzeum}',
                    '{nazwa_ankiety}',
                    '{nazwa_pytania}',
                    '{imie_nazwisko}',
                    '{imie}',
                    '{nazwisko}',
                    '{email}',
                );
                break;
            case 'notification_poll_section_permission':
                variables.push(
                    '{nazwa_muzeum}',
                    '{nazwa_ankiety}',
                    '{nazwa_sekcji}',
                    '{imie_nazwisko}',
                    '{imie}',
                    '{nazwisko}',
                    '{email}',
                );
                break;
            case 'notification_poll_permission':
                variables.push(
                    '{nazwa_muzeum}',
                    '{nazwa_ankiety}',
                    '{imie_nazwisko}',
                    '{imie}',
                    '{nazwisko}',
                    '{email}',
                );
                break;
            case 'notification_poll_sent':
                variables.push(
                    '{nazwa_muzeum}',
                    '{nazwa_ankiety}',
                    '{imie_nazwisko}',
                    '{imie}',
                    '{nazwisko}',
                    '{email}',
                );
                break;
            case 'notification_section_unlocked':
                variables.push(
                    '{nazwa_muzeum}',
                    '{nazwa_ankiety}',
                    '{nazwa_sekcji}',
                    '{imie_nazwisko}',
                    '{imie}',
                    '{nazwisko}',
                    '{email}',
                );
                break;
            case 'notification_poll_needs_correction':
                variables.push(
                    '{nazwa_muzeum}',
                    '{nazwa_ankiety}',
                    '{imie_nazwisko}',
                    '{imie}',
                    '{nazwisko}',
                    '{email}',
                );
                break;
            case 'notification_poll_accepted':
                variables.push(
                    '{nazwa_muzeum}',
                    '{nazwa_ankiety}',
                    '{imie_nazwisko}',
                    '{imie}',
                    '{nazwisko}',
                    '{email}',
                );
                break;
            case 'notification_section_ready':
                variables.push(
                    '{nazwa_muzeum}',
                    '{nazwa_ankiety}',
                    '{imie_nazwisko}',
                    '{nazwa_sekcji}',
                    '{imie}',
                    '{nazwisko}',
                    '{email}',
                );
                break;
        }

        return variables.length ? <Typography>Dostępne zmienne:<br/>{variables.join(' ')}</Typography> : '';
    };

    render() {
        if (this.state.isLoading) {
            return this.showLoader();
        }
        // const { classes } = this.props;

        let fields = [];
        if (!this.setting_name) {
            fields.push(
                {
                    name: 'name',
                    type: 'text',
                    label: 'Nazwa',
                },
                { type: 'divider' },
                {
                    name: 'title',
                    type: 'text',
                    label: 'Tutuł',
                    width: 816,
                },
                { type: 'divider' },
            );
        }
        fields.push(
            {
                name: 'value',
                type: this.getObject('type'),
                label: '',
                width: '100%',
            },
            { type: 'divider' },
        );

        return <>
            <PaperRoot>
                <div>
                    <NCForm
                        fieldsContainerStyle={this.getObject('name') === 'dashboard' ? { marginLeft: -10, marginRight: -10 } : {}}
                        fields={fields}
                        title={this.getTitle()}
                        values={this.getValues()}
                        errors={this.getErrors()}
                        onChange={this.changeHandler}
                        hideSubmitButton
                        // saveHandler={this.saveObject}
                    />
                    {this.getVariables()}
                </div>
            </PaperRoot>

            <FabContainer>
                <FabLink title="Wróć" to="/settings" color="default"><ArrowBackIcon/></FabLink>

                {User.hasPermissionTo('edit-settings') && (
                    <FabButton title="Zapisz" onClick={this.saveObject} color="primary"><SaveIcon/></FabButton>
                )}
            </FabContainer>
        </>
    }
}

const styles = theme => ({});

export default withStyles(styles)(SettingsEdit);
