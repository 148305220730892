import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import MaterialTable, { MTableBody, MTableBodyRow, MTableHeader, MTableCell, MTableAction, MTableToolbar, MTableFilterRow } from 'material-table';
import TablePagination from '@material-ui/core/TablePagination';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

import AddBoxIcon from '@material-ui/icons/AddBox';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CheckIcon from '@material-ui/icons/Check';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import RemoveIcon from '@material-ui/icons/Remove';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import SearchIcon from '@material-ui/icons/Search';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import TooltipIconButton from "./TooltipIconButton";
import classNames from 'classnames';
import { arrayRemove, dotGet, getItemIndexFromArrayByAttribute } from "./Helpers";
import ConfirmDialog from "./ConfirmDialog";
import Axios from "axios";

const SortableTableBody = SortableContainer(props => {
    return <MTableBody {...props} />;
});

const SortableTableRow = SortableElement(props => {
    return <MTableBodyRow {...props} />;
});

const SortableTableHandle = SortableHandle(props => {
    return <TooltipIconButton title="Sortuj" style={{ cursor: 'ns-resize' }}>
        <DragIndicatorIcon/>
    </TooltipIconButton>
});

class DataTable extends Component {
    static localization = {
        body: {
            emptyDataSourceMessage: 'Brak wyników', // overriden later
            filterRow: {
                filterTooltip: 'Filtruj',
            },
            editRow: {
                saveTooltip: 'Zapisz',
                cancelTooltip: 'Anuluj',
                deleteText: 'Usuń',
            },
            addTooltip: 'Dodaj',
            deleteTooltip: 'Usuń',
            editTooltip: 'Zmień',
        },
        header: {
            actions: 'Akcje',
        },
        grouping: {
            groupedBy: '',
            placeholder: '',
        },
        pagination: {
            firstTooltip: 'Pierwsza',
            previousTooltip: 'Poprzednia',
            nextTooltip: 'Następna',
            labelDisplayedRows: '{from}-{to} z {count}',
            labelRowsPerPage: 'Wyników na stronie:',
            lastTooltip: 'Ostatnia',
            labelRowsSelect: 'pozycji',
        },
        toolbar: {
            addRemoveColumns: 'Dodaj lub usuń kolumny',
            nRowsSelected: '{0} pozycji(e) zaznaczono',
            showColumnsTitle: 'Pokaż kolumny',
            showColumnsAriaLabel: 'Pokaż kolumny',
            exportTitle: 'Eksport',
            exportAriaLabel: 'Eksport',
            exportCSVName: 'Zapisz jako CSV',
            searchTooltip: 'Szukaj',
            searchPlaceholder: 'Szukaj',
        },
    };

    tableRef = this.props.tableRef;
    actions = [];
    selectedRows = [];
    options = {
        actionsColumnIndex: -1,
        filtering: true,
        grouping: false,
        sorting: true,
        search: false,
        initialPage: 0,
        pageSize: 10,
        pageSizeOptions: [2, 4, 6, 8, 10, 15, 20, 25, 50, 100], // don't forget to adjust pageSizeOptions.length in the code below
        debounceInterval: 500,
        selection: false,
        // paginationType: 'stepped',
        // padding: 'dense',
        emptyRowsWhenPaging: false,
        tableLayout: 'auto',
    };
    state = {
        restoreDialog: false,
    };

    constructor(props) {
        super(props);

        DataTable.localization.body.emptyDataSourceMessage = <div className={props.classes.noResults}>Brak wyników</div>;

        const { pageSize, pageSizeOptions } = this.options;

        if (!!props.storageKey) {
            // restoring initialPage is disabled for now due to a bug in MaterialTable
            // this.options.initialPage = parseInt(localStorage.getItem(props.storageKey + '_page')) || initialPage;
            this.options.pageSize = parseInt(localStorage.getItem(props.storageKey + '_pageSize')) || pageSize;
            if (!pageSizeOptions.includes(this.options.pageSize)) {
                // page size must have been set to show all rows so we lower this value down
                this.options.pageSize = pageSize;
            }
        }

        if (!!props.editLink) {
            this.actions.push(rowData => {
                return rowData.deleted_at ? {
                    tooltip: 'Przywróć',
                    icon: RestoreFromTrashIcon,
                    iconProps: {
                        className: props.classes.actionIcon,
                        color: 'secondary',
                    },
                    onClick: (event, rowData) => this.openRestoreDialog(props.editLink(rowData)),
                } : {
                    tooltip: 'Edytuj',
                    icon: () => <Link to={props.editLink(rowData)} className={props.classes.actionIcon}><EditIcon/></Link>,
                    // onClick: (event, rowData) => alert('ID: ' + rowData.id)
                    // disabled: false,
                    // hidden: false,
                    // isFreeAction: false,
                }
            });
        }

        if (!!props.checkable) {
            this.actions.push(rowData => ({
                tooltip: rowData.unchecked ? 'Zaznacz' : 'Odznacz',
                icon: rowData.unchecked ? ClearIcon : CheckIcon,
                iconProps: {
                    className: props.classes.actionIcon,
                },
                onClick: (event, rowData) => {
                    rowData.unchecked = !rowData.unchecked;
                    if (props.onCheck) {
                        props.onCheck()
                    }
                }
            }));
        }
    }

    onChangePage = (initialPage) => {
        const { storageKey, selectionPK } = this.props;
        if (!!storageKey) {
            localStorage.setItem(storageKey + '_page', initialPage);
        }

        if (this.selectedRows.length) {
            // recall checked
            this.tableRef.current.state.data.forEach(row => {
                const index = getItemIndexFromArrayByAttribute(this.selectedRows, dotGet(row, selectionPK), selectionPK);
                if (index > -1) {
                    row.tableData.checked = true;
                }
            });
            this.tableRef.current.onSelectionChange(); // force checkboxes reload
        }
    };

    onChangeRowsPerPage = (pageSize) => {
        this.options.pageSize = pageSize;
        if (!!this.props.storageKey) {
            localStorage.setItem(this.props.storageKey + '_pageSize', pageSize);
            this.onChangePage(0);
        }
    };

    onSortEnd = props => this.props.onSortEnd(props); // props: ({ newIndex, oldIndex, collection, isKeySorting }, event)

    openRestoreDialog = (url) => {
        this.setState({ restoreDialog: url.replace('/edit', '') }); // convert frontend to backend url
    };

    restoreObject = () => {
        Axios.patch(this.state.restoreDialog).then(response => {
            // this. StateData(response.data, { restoreDialog: false });
            this.setState({ restoreDialog: false }, () => this.tableRef.current.onFilterChangeDebounce());
        }).catch(this.catchErrors);
    };

    render() {
        const { classes, showLink, tableRef, actions, options, sortable, useDragHandle, onSelectionChange, selectionPK, ...other } = this.props;
        if (sortable && useDragHandle) {
            actions.push({ component: <SortableTableHandle/> });
        }

        this.props.columns.forEach((column, key) => {
            const v = localStorage.getItem(this.props.storageKey + '_filters_' + column.field);
            if (v !== null && v.length) {
                column.defaultFilter = JSON.parse(v);
            }
        });

        return <div className={classes.tableContainer} ref={el => this.sortContainerRef = el}>
            <ConfirmDialog title="Przywracanie" open={!!this.state.restoreDialog} onClose={() => this.setState({ restoreDialog: false })} onConfirm={this.restoreObject}>
                Czy na pewno chcesz przywrócić wybraną pozycję?
            </ConfirmDialog>
            <MaterialTable
                tableRef={this.tableRef}
                actions={this.actions.concat(actions || [])}
                options={Object.assign(this.options, options)}
                onChangePage={this.onChangePage}
                onChangeRowsPerPage={this.onChangeRowsPerPage}
                onSelectionChange={(rows, rowData) => {
                    if (typeof this.props.data === 'function') { // remote data
                        if (rowData) { // single row checked
                            const index = getItemIndexFromArrayByAttribute(this.selectedRows, dotGet(rowData, selectionPK), selectionPK);
                            if (rowData.tableData.checked) {
                                if (index === -1) {
                                    this.selectedRows.push(rowData);
                                }
                            } else {
                                if (index > -1) {
                                    this.selectedRows = arrayRemove(this.selectedRows, index);
                                }
                            }
                        } else {
                            if (rows.length) { // all rows checked
                                rows.forEach(row => {
                                    const index = getItemIndexFromArrayByAttribute(this.selectedRows, dotGet(row, selectionPK), selectionPK);
                                    if (index === -1) {
                                        this.selectedRows.push(row);
                                    }
                                });
                            } else { // all rows unchecked
                                this.tableRef.current.state.data.forEach(row => {
                                    const index = getItemIndexFromArrayByAttribute(this.selectedRows, dotGet(row, selectionPK), selectionPK);
                                    if (index > -1) {
                                        this.selectedRows = arrayRemove(this.selectedRows, index);
                                    }
                                });
                            }
                        }

                        !!onSelectionChange && onSelectionChange(this.selectedRows, rowData);
                    } else {
                        !!onSelectionChange && onSelectionChange(rows, rowData);
                    }
                }}
                components={{
                    Toolbar: (props) => {
                        let other = {};
                        if (typeof this.props.data === 'function') {
                            other.selectedRows = this.selectedRows;
                        }

                        return <MTableToolbar {...props} {...other} />
                    },
                    Body: (props) => {
                        return sortable ? <SortableTableBody
                            useDragHandle={useDragHandle}
                            distance={4} // prevents click events from being swallowed
                            lockAxis="y"
                            onSortEnd={this.onSortEnd}
                            helperClass="draggedRow"
                            helperContainer={() => this.sortContainerRef.getElementsByClassName('MuiTableBody-root')[0]}
                            {...props}
                        /> : <MTableBody {...props} />;
                    },
                    Row: (props) => {
                        return sortable ? <SortableTableRow {...props} /> : <MTableBodyRow {...props} />
                    },
                    Header: (props) => {
                        return <MTableHeader {...props} />
                    },
                    Cell: (props) => {
                        const linkValue = typeof showLink === 'function' ? showLink(props.rowData) : showLink;
                        let cell = {};
                        if (props.columnDef.field === 'function') {
                            cell.value = <div className={classes.cellWrap}>
                                <div className={classNames(classes.cellContent, classes.cellCenter)}>{props.columnDef.callback(props.rowData)}</div>
                            </div>;
                        } else if (!linkValue || props.rowData.deleted_at) {
                            cell.value = <div className={classes.cellWrap}>
                                <div className={classes.cellContent}>{props.value}</div>
                            </div>;
                        } else {
                            cell.value = <Link className={classes.cellWrap} to={linkValue}>
                                <div className={classes.cellContent}>{props.value}</div>
                            </Link>;
                        }
                        return <MTableCell {...Object.assign({}, props, cell)} />;
                    },
                    Action: (props) => {
                        // const { action, data, size } = props; // all available props
                        let { action, data } = props;
                        action = typeof action === 'function' ? action(data) : action;
                        const { component } = action;
                        if (component) {
                            return <span>{component}</span>; // MTableAction returns a button wrapped in <span/>
                        }

                        return <MTableAction {...props} /> // default Action
                    },
                    Pagination: (props) => {
                        let { pageSizeOptions } = this.options;
                        if (pageSizeOptions.length === 10) { // do it only once
                            if (this.tableRef.current) {
                                const { totalCount } = this.tableRef.current.state.query;
                                if (totalCount > pageSizeOptions.slice(-1)[0]) {
                                    pageSizeOptions.push(totalCount);
                                }
                            }
                        }

                        return <TablePagination rowsPerPageOptions={pageSizeOptions} {...props} />
                    },
                    FilterRow: props => {
                        return <MTableFilterRow {...props} onFilterChanged={(columnKey, value) => {
                            props.onFilterChanged(columnKey, value);
                            const column = this.props.columns[columnKey];
                            localStorage.setItem(this.props.storageKey + '_filters_' + column.field, JSON.stringify(value));
                        }}/>;
                    }
                }}
                localization={DataTable.localization}
                icons={{
                    // we set icons manually, otherwise we'd have to add: <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"/>
                    Add: AddBoxIcon,
                    Check: CheckIcon,
                    Clear: ClearIcon,
                    Delete: DeleteOutlineIcon,
                    DetailPanel: ChevronRightIcon,
                    Edit: EditIcon,
                    Export: SaveAltIcon,
                    Filter: SearchIcon,
                    FirstPage: FirstPageIcon,
                    LastPage: LastPageIcon,
                    NextPage: ChevronRightIcon,
                    PreviousPage: ChevronLeftIcon,
                    ResetSearch: ClearIcon,
                    Search: SearchIcon,
                    SortArrow: ArrowUpwardIcon,
                    ThirdStateCheck: RemoveIcon,
                    ViewColumn: ViewColumnIcon,
                }}
                {...other}
            />
        </div>
    }
}

DataTable.propTypes = {
    tableRef: PropTypes.any,
    storageKey: PropTypes.string,
    actions: PropTypes.array,
    options: PropTypes.object,
    data: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.func,
    ]),
    columns: PropTypes.array.isRequired,
    title: PropTypes.any,
    showLink: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
    ]),
    editLink: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
    ]),
    checkable: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
    ]),
    sortable: PropTypes.bool,
    useDragHandle: PropTypes.bool,
    onSortEnd: PropTypes.func,
    total: PropTypes.number,
    selectionPK: PropTypes.string,
};
DataTable.defaultProps = {
    tableRef: React.createRef(),
    actions: [],
    sortable: false,
    useDragHandle: true,
    onSortEnd: () => {},
    total: 0,
    selectionPK: 'id',
};

const styles = theme => ({
    tableContainer: {
        '& input[type="search"]': {
            borderBottom: '1px solid #f00',
            zIndex: 10,
            marginLeft: -24,
            paddingLeft: 24,
        },
        '& input[type="search"][value=""]': {
            borderBottom: '1px solid transparent',
        },
        '& .MuiFormLabel-filled ~ div > .MuiSelect-select': {
            borderBottom: '1px solid #f00',
            zIndex: 10,
        },
        '& .draggedRow': {
            display: 'table !important', // TODO: lock table cell dimensions
        },
        '& thead .MuiTableSortLabel-root.Mui-disabled .MuiTableSortLabel-icon': {
            display: 'none',
        },
        '& tbody': {
            height: '100%',
            '& tr': {
                '& td': {
                    backgroundColor: '#fff',
                    padding: 0,
                    height: '100%',
                    '& .MuiSelect-select': {
                        height: 17,
                    },
                },
            },
            '& tr:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.07)',
            },
            '& .MuiInputAdornment-positionStart': {
                marginRight: 0,
            },
            '& .MuiFormControl-root': {
                paddingLeft: 14,
            },
            '& .MuiInputBase-root': {
                marginTop: 0,
            },
            '& .MuiTextField-root': {
                width: '100%',
            },
            '& .MuiTableRow-root[level="0"]': {
                '& td:first-child': {
                    borderTop: '1px solid #e0e0e0',
                },
            },
            '& .MuiTableRow-root[level="1"]': {
                '& td:first-child': {
                    borderBottom: 'none',
                },
                '& td:nth-child(2)': {
                    borderLeft: '1px solid #e0e0e0',
                    textIndent: theme.spacing(2),
                },
            },
            // '& tr:not([index])': {
            //     '& input:not([value=""])': {
            //         borderBottom: '2px solid #f00',
            //     },
            // },
            // '& tr:hover td:not([colspan])': {
        },
    },
    noResults: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    actionIcon: {
        width: 48,
        height: 48,
        color: 'inherit',
        padding: 12,
        margin: -12,
    },
    cellWrap: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100%',
        minHeight: 48,
        textAlign: 'left',
        padding: '0 0 0 16px',
        textDecoration: 'inherit',
        color: 'inherit',
    },
    cellContent: {
        display: 'flex',
        flexDirection: 'row',
    },
    cellCenter: {
        alignItems: 'center',
    },
});

export default withStyles(styles)(DataTable);
