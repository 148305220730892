import React from 'react';
import { withRouter } from "react-router";
import DataComponent from "../shared/DataComponent";
import FabContainer from "../shared/FabContainer";
import DataTable from "../shared/DataTable";

import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import User from "../auth/User";
import { FabLink } from "../shared/FabButton";
import Poll from "../../models/Poll";

class PollList extends DataComponent {
    static permissions = ['view-polls'];
    project_id = this.props.match.params.project_id;
    url = '/projects/' + this.project_id + '/polls';
    tableColumns = {
        id: 'ID',
        name: 'Nazwa ankiety',
        type_string: { field: 'type', title: 'Rodzaj', lookup: Poll.types },
        year: 'Okres',
        'user.full_name': 'Właściciel',
        starts_at: 'Data rozpoczęcia',
        ends_at: 'Data zakończenia',
        created_at: 'Data dodania',
    };

    constructor(props) {
        super(props);

        this.addDeletedAtColumn();
    }

    render() {
        if (this.state.isLoading) {
            return this.showLoader();
        }

        return (
            <>
                <DataTable
                    storageKey={this.constructor.name}
                    title={"Lista ankiet w projekcie " + this.getObject('name')}
                    columns={this.getTableColumns()}
                    data={this.loadTableData(this.url)}
                    showLink={User.hasPermissionTo('view-polls') ? (rowData) => '/projects/' + this.project_id + '/polls/' + rowData.id + '/list' : null}
                    editLink={User.hasPermissionTo('edit-polls') ? (rowData) => '/projects/' + this.project_id + '/polls/' + rowData.id + '/edit' : null}
                />

                <FabContainer>
                    <FabLink title="Wróć" to="/projects" color="default"><ArrowBackIcon/></FabLink>

                    {User.hasPermissionTo('edit-polls') && (
                        <FabLink title="Dodaj ankietę" to={"/projects/" + this.getObject('id') + '/polls/add'} color="primary">
                            <AddIcon/>
                        </FabLink>
                    )}
                </FabContainer>
            </>
        );
    }
}

export default withRouter(PollList);
