import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import NCForm from '../nc-form/NCForm';

import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';

import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import DataComponent from "../shared/DataComponent";
import Axios from 'axios';

import { DialogTitle, DialogContent } from "../shared/Dialog";
import Typography from "@material-ui/core/Typography";
import { IconButton } from "@material-ui/core";
import PropTypes from "prop-types";
import User from "../auth/User";
import clsx from 'clsx';
import { getObjCopyFromArrayByAttribute, getObjFromArrayByAttribute } from "../shared/Helpers";
import FabButton from "../shared/FabButton";

const styles = theme => ({
    root: {
        // ...theme.mixins.gutters(),
        paddingTop: theme.spacing(),
        paddingBottom: theme.spacing(),
    },
    button: {
        marginRight: 20,
    },
    note: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(),
        paddingBottom: theme.spacing(2),
        margin: theme.spacing(2),
    },
    delete: {
        background: 'rgba(255, 0, 0, 0.3)',
    },
});

class MuseumTabNotes extends DataComponent {
    museum_id = this.props.museum_id;
    url = '/museums/' + this.museum_id + '/notes';

    constructor(props) {
        super(props);

        this.state.addDialog = false;
        this.state.editNoteId = false;
        this.state.deleteNoteId = false;
        this.state.values = {};

        if(User.hasPermissionTo('edit-museum-notes')){
            props.buttons.push(<FabButton key="addNote" onClick={this.openAddDialog} color="primary" title="Dodaj notatkę" className={props.classes.button}>
                <AddIcon/>
            </FabButton>);
        }
    }

    saveObject = () => {
        const { editNoteId, values } = this.state;
        if (editNoteId) {
            Axios.put(this.url + '/' + editNoteId, values).then(response => {
                this.setState({
                    editNoteId: false,
                    data: response.data,
                    values: {},
                    errors: {},
                });
            }).catch(this.catchErrors);
        } else {
            Axios.post(this.url, values).then(response => {
                this.setState({
                    addDialog: false,
                    data: response.data,
                    values: {},
                    errors: {},
                });
            }).catch(this.catchErrors);
        }
    };

    deleteObject = () => {
        Axios.delete(this.url,{data: {note_id: this.state.deleteNoteId}}).then(response => {
            this.setState({
                isLoading: false,
                deleteNoteId: false,
                data: response.data,
            });
        }).catch(this.catchErrors);
    };

    openEditDialog = (note_id) => event => {
        this.setState({
            editNoteId: note_id,
            values: getObjCopyFromArrayByAttribute(this.state.data.list, note_id),
            dialogTitle: 'Edycja adnotacji',
            saveLabel: 'Zapisz',
        });
    };

    openAddDialog = event => {
        this.setState({ addDialog: true, dialogTitle: 'Dodaj adnotację', saveLabel: 'Dodaj' });
    };

    openDeleteDialog = (note_id) => event => {
        this.setState({ deleteNoteId: note_id });
    };

    closeDeleteDialog = () => {
        this.setState({ deleteNoteId: false });
    };

    closeDialogs = () => {
        this.setState({ deleteNoteId: false, editNoteId: false, addDialog: false, values: {}, errors: {} });
    };

    render() {
        if (this.state.isLoading) {
            return this.showLoader();
        }
        const { classes } = this.props;
        const { data } = this.state;

        const fields = [
            {
                name: 'title',
                type: 'text',
                label: 'Tytuł',
                width: 600,
            },
            {
                name: 'content',
                type: 'tinyLessBasic',
                label: 'Treść',
                width: 600,
            },
        ];

        return (<Paper className={classes.root}>
            {data.list.length ? data.list.map((note, key) =>
                <Paper key={key} className={clsx(classes.note, this.state.editNoteId === note.id || note.id === this.state.deleteNoteId ? classes.delete : null)}>
                    <Typography variant="h6" component="h6">
                        {note.title}
                        {User.hasPermissionTo('edit-museum-notes') && <>
                            <IconButton onClick={this.openDeleteDialog(note.id)}><DeleteIcon/></IconButton>
                            <IconButton onClick={this.openEditDialog(note.id)}><EditIcon/></IconButton>
                        </>}
                    </Typography>
                    <Typography component="p" dangerouslySetInnerHTML={{ __html: note.content }}/>
                </Paper>
            ) : (
                <Typography style={{ textAlign: 'center', padding: 40 }}>Brak adnotacji</Typography>
            )}

            <Dialog open={!!(this.state.addDialog || this.state.editNoteId)} onClose={this.closeDialogs}>
                <DialogTitle onClose={this.closeDialogs}>{this.state.dialogTitle}</DialogTitle>
                <DialogContent>
                    <NCForm fields={fields}
                            values={this.getValues()}
                            errors={this.getErrors()}
                            onChange={this.changeHandler}
                            hideSubmitButton
                    />
                    <DialogActions>
                        <Button variant="outlined" onClick={this.closeDialogs} color="default">Anuluj</Button>
                        <Button variant="contained" onClick={this.saveObject} color="primary">{this.state.saveLabel}</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>

            <Dialog open={!!this.state.deleteNoteId} onClose={this.closeDeleteDialog}>
                <DialogTitle onClose={this.closeDeleteDialog}>Usuwanie</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <strong>{getObjFromArrayByAttribute(data.list, this.state.deleteNoteId).title}</strong><br/><br/>
                        Czy na pewno chcesz usunąć tę adnotację?
                        <br/><br/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={this.closeDeleteDialog} color="default">Anuluj</Button>
                    <Button variant="contained" onClick={this.deleteObject} color="secondary">Potwierdzam</Button>
                </DialogActions>
            </Dialog>
        </Paper>);
    }
}

MuseumTabNotes.propTypes = {
    addNew: PropTypes.bool,
    buttons: PropTypes.array,
    museum_id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]).isRequired,
};
MuseumTabNotes.defaultProps = {
    addNew: false,
    buttons: [],
};

export default withStyles(styles)(MuseumTabNotes);
