import React from 'react';
import Axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import NCForm from '../nc-form/NCForm';
import '../../react-pivottable/pivottable.css';
import DataTable from "../shared/DataTable";
import Typography from "@material-ui/core/Typography";
import ReactExport from "react-export-excel";
import { clone, debounce, dotGet, getObjValuesFromArrayByAttribute, getSelectOptions } from "../shared/Helpers";
import TooltipIconButton from "../shared/TooltipIconButton";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Museum from "../../models/Museum";
import BaseComponent from "../shared/BaseComponent";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

DataTable.localization.toolbar.exportPDFName = 'Zapisz jako XLSX';

class AnswersReport extends BaseComponent {
    variants = {
        questions: [],
        years: [],
    };
    tableRef = React.createRef();
    dataFields = Museum.dataColumns;
    state = {
        loading: true,
        data: [],
        variants: { availableYears: [], availableQuestions: [] },
        values: {},
        xlsx: '',
    };

    updateHeight = debounce(() => {
        const head = document.getElementsByClassName('MuiTableCell-head');
        if (head.length) {
            let height = head[1].scrollHeight;
            document.documentElement.style.setProperty('--th-height', height + 'px');
        }
    });

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.updateHeight();
    }

    componentDidMount() {
        let questions = this.props.params.questions.map((v) => {return { id: v.id, cell: v.cell }; });
        let polls = this.props.params.polls.map((v) => v.id);
        let museums = this.props.params.museums.map((v) => v.id);
        let conditions = this.props.params.conditions;
        const { poll, state, filling_type, filling_year, question_type, estimated_data, data, museum } = this.props.params.values;

        Axios.post('/data-reports/getAnswersData', {
            questions, polls, museums, state,
            poll, filling_type, filling_year, question_type, estimated_data,
            data, museum, conditions,
        }).then((response) => {
            let variants = this.collectVariants(response.data.data);

            if (variants.availableQuestions.length > 0 && variants.availableYears.length > 0)
            {
                let values = {
                    question: getObjValuesFromArrayByAttribute(variants.availableQuestions, 'value'),
                    year: getObjValuesFromArrayByAttribute(variants.availableYears, 'value'),
                    fields: [],
                };

                this.variants.questions = clone(values.question);
                this.variants.years = clone(values.year);

                Object.assign(values, this.props.params.additionalData.answers);

                this.setState({
                    loading: false,
                    data : response.data.data,
                    variants : variants,
                    values : values,
                });
            } else {
                this.setState({
                    loading: false,
                });
            }
        });
    }

    collectVariants(data)
    {
        let availableQuestions = {}
        let availableYears = {};

        data.forEach((data) => {
            availableQuestions[data.question_id] = {value: data.question_id, text: data.question_name};
            availableYears[data.year] = {value: data.year, text: data.year};
        });

        availableYears = Object.keys(availableYears).map((v) => {
            return {value: availableYears[v].value, text: availableYears[v].text};
        });

        availableQuestions = Object.keys(availableQuestions).map((v) => {
            return {value: availableQuestions[v].value, text: availableQuestions[v].text};
        });

        return {availableQuestions, availableYears};
    }

    getTableData() {
        const { data, values } = this.state;
        let museumsData = {};
        let dynamicTableAnswers = {};

        data.forEach(row => {
            if (!values.question.includes(row.question_id)) {
                return;
            }
            if (!values.year.includes(row.year)) {
                return;
            }

            if (!museumsData[row.museum_idx]) {
                museumsData[row.museum_idx] = {
                    museum: row.museum,
                    museum_idx: row.museum_idx,
                    ref_idx: row.museum_idx,
                    parent_idx: row.parent_idx,
                    museum_name: row.museum_name,
                    museum_type: row.museum_type,
                    poll_type: row.museum_type,
                };
                values.fields.forEach(field => {
                    museumsData[row.museum_idx][field] = dotGet(row.museum, field);
                });
            }

            const qId = row.question_id + '-' + row.year;
            if (row.question_type === 'dynamic_table') {
                if (!dynamicTableAnswers[row.museum_idx]) {
                    dynamicTableAnswers[row.museum_idx] = {};
                }
                try {
                    dynamicTableAnswers[row.museum_idx][qId] = JSON.parse(row.value);
                } catch (e) {
                    dynamicTableAnswers[row.museum_idx][qId] = [];
                }
                museumsData[row.museum_idx][qId] = dynamicTableAnswers[row.museum_idx][qId].shift() || ''; // leave the first value in primary row
            } else {
                museumsData[row.museum_idx][qId] = row.value;
            }
        });

        let destData = Object.values(museumsData);

        Object.entries(dynamicTableAnswers).forEach(([museum_idx, questions]) => {
            questions = Object.entries(questions);
            // const item = getItemFromArrayByAttribute(destData, museum_idx, 'museum_idx');
            const item = museumsData[museum_idx];
            let length = 0;
            questions.forEach(([qId, answers]) => {
                length = Math.max(length, answers.length);
            });
            for (let i = 0; i < length; i++) {
                let newRow = {
                    museum_idx: item.museum_idx,
                    ref_idx: museum_idx + '-' + i,
                    parent_idx: item.museum_idx,
                    museum_name: item.museum_name,
                    museum_type: item.museum_type,
                    poll_type: item.museum_type,
                };
                values.fields.forEach(field => {
                    newRow[field] = dotGet(item.museum, field);
                });
                questions.forEach(([qId, answers]) => {
                    newRow[qId] = answers.shift() || '';
                });
                destData.push(newRow);
            }
        });

        return destData;
    }

    getTableColumns() {
        let columns = [
            { title: 'ID', field: 'museum_idx' },
            { title: 'Instytucja', field: 'museum_name' },
            { title: 'Typ instytucji', field: 'museum_type' },
        ];

        (this.state.values.fields || []).forEach(field => {
            columns.push({
                title: this.dataFields[field],
                field,
            });
        });

        columns.push({ title: 'Typ ankiety', field: 'poll_type' });

        this.state.values.question && this.state.values.question.forEach((question) => {
            let questionObject = this.state.variants.availableQuestions.filter((q) => {
                return q.value === question;
            })[0];

            this.state.values.year.forEach((year) => {
                let yearObject = this.state.variants.availableYears.filter((y) => {
                    return y.value === year;
                })[0];

                columns.push({
                    title: '[' + yearObject.text + ']' + questionObject.text,
                    field: question + '-' + year,
                });
            });
        });


        /* return [
             {
                 title: "Muzeum",
                 field: "museum",
             },
             {
                 title: "Wartosć",
                 field: "value"
             },
         ];*/

        return columns;
    }

    changeSettings = (name, value) => {
        let values = this.state.values;
        values[name] = value;

        this.setState({values});
    }

    exportXlsx = (columns, renderData) => {
        let outputData = [];
        renderData.forEach(row => {
            outputData.push(row);
            if (row.tableData.childRows) {
                row.tableData.childRows.forEach(child => {
                    outputData.push(child);
                });
            }
        });
        this.setState({
            xlsx: <ExcelFile key={'' + -new Date()} hideElement={true} filename="Export - lista odpowiedzi">
                <ExcelSheet data={outputData} name="Lista odpowiedzi">
                    {columns.map((row, index) => <ExcelColumn key={index} label={row.title} value={row.field}/>)}
                    {/* value callback: value={(col) => col.is_married ? "Married" : "Single"} */}
                </ExcelSheet>
            </ExcelFile>
        });
    };

    render() {
        const { classes } = this.props;
        const { loading, values, variants, xlsx } = this.state;

        const fields = [
            {
                type: 'custom',
                component: <TooltipIconButton title="Zaznacz wszystkie pytania" onClick={() => this.changeSettings('question', this.variants.questions)}>
                    <CheckBoxIcon/>
                </TooltipIconButton>
            },
            {
                type: 'custom',
                component: <TooltipIconButton title="Odznacz wszystkie pytania" onClick={() => this.changeSettings('question', [])}>
                    <CheckBoxOutlineBlankIcon/>
                </TooltipIconButton>
            },
            {
                type: 'multiselect',
                name: 'question',
                label: 'Pytania',
                options: variants.availableQuestions,
                width : '95%',
                containerStyle: { width: '80%' },
            },
            { type: 'divider' },
            {
                type: 'custom',
                component: <TooltipIconButton title="Zaznacz wszystkie pola" onClick={() => this.changeSettings('fields', Object.keys(this.dataFields))}>
                    <CheckBoxIcon/>
                </TooltipIconButton>
            },
            {
                type: 'custom',
                component: <TooltipIconButton title="Odznacz wszystkie pola" onClick={() => this.changeSettings('fields', [])}>
                    <CheckBoxOutlineBlankIcon/>
                </TooltipIconButton>
            },
            {
                type: 'multiselect',
                name: 'fields',
                label: 'Dodatkowe pola',
                options: getSelectOptions(this.dataFields),
                width : '95%',
                containerStyle: { width: '80%' },
            },
            { type: 'divider' },
            {
                type: 'custom',
                component: <TooltipIconButton title="Zaznacz wszystkie lata" onClick={() => this.changeSettings('year', this.variants.years)}>
                    <CheckBoxIcon/>
                </TooltipIconButton>
            },
            {
                type: 'custom',
                component: <TooltipIconButton title="Odznacz wszystkie lata" onClick={() => this.changeSettings('year', [])}>
                    <CheckBoxOutlineBlankIcon/>
                </TooltipIconButton>
            },
            {
                type: 'multiselect',
                name: 'year',
                label: 'Lata',
                options: variants.availableYears,
                width : '100%',
                containerStyle: { width: '10%' },
            },
        ];

        return <>
            <div>
                <NCForm
                    style={{ paddingRight: 24 }}
                    onChange={this.changeSettings}
                    fields={fields}
                    values={values}
                    hideSubmitButton={true}
                    saveButtonLabel="Aktualizuj mapę"
                    title="Ustawienia raportu"
                />
            </div>
            <div className={classes.tableContainer}>
                {loading ? (
                    <Typography style={{ marginTop: 100 }}>Ładowanie danych...</Typography>
                ) : (
                    <DataTable
                        tableRef={this.tableRef}
                        storageKey={this.constructor.name}
                        style={{ minWidth: '100%' }}
                        data={this.getTableData()}
                        columns={this.getTableColumns()}
                        parentChildData={(row, rows) => rows.find(v => v.ref_idx === row.parent_idx)}
                        title="Dane"
                        options={{
                            exportButton: { pdf: true },
                            exportPdf: this.exportXlsx,
                            exportAllData: true,
                        }}
                    />
                )}
                {xlsx}
            </div>
        </>
    }
}

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    header: {
        marginBottom: theme.spacing(),
    },
    tableContainer: {
        '& > div > div > div': {
            overflow: 'initial !important',
            '& > div > div': {
                overflow: 'initial !important',
                '& th': {
                    top: 'calc(-1 * var(--th-height) + 96px)',
                    verticalAlign: 'bottom',
                }
            }
        },
    },
});

export default withStyles(styles)(AnswersReport);
