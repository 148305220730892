import React from 'react';
import DataComponent from "../shared/DataComponent";
import DataTable from "../shared/DataTable";
import GroupIcon from '@material-ui/icons/Group';
import ConfirmDialog from "../shared/ConfirmDialog";
import Axios from "axios";
import Typography from "@material-ui/core/Typography";
import User from "../auth/User";

class NotificationList extends DataComponent {
    static permissions = ['view-all-notifications'];
    url = '/notifications/all';
    title = 'Powiadomienia wszystkich użytkowników';
    tableColumns = {
        id: 'ID',
        created_at: 'Data wydarzenia',
        'user.full_name': 'Użytkownik',
        'museum.name': 'Muzeum',
        content: 'Treść',
    };
    actions = [];

    constructor(props) {
        super(props);

        this.state.usersDialog = false;
        if (User.hasPermissionTo('view-all-notifications')) {
            this.actions.push(rowData => ({
                tooltip: 'Użytkownicy',
                icon: GroupIcon,
                onClick: this.showUsersDialog(rowData),
            }));
        }
    }

    closeDialogs = () => {
        this.setState({ usersDialog: false });
    };

    showUsersDialog = (notification) => () => {
        Axios.get('/notifications/users/' + notification.id).then(response => {
            const { list } = response.data;
            this.setState({
                usersDialog: {
                    notification,
                    list,
                }
            });
        }).catch(this.catchErrors);
    };

    render() {
        if (this.state.isLoading) {
            return this.showLoader();
        }

        const { usersDialog } = this.state;

        return <>
            <DataTable
                storageKey={this.constructor.name}
                title={this.title}
                columns={this.getTableColumns()}
                data={this.loadTableData(this.url)}
                showLink={(rowData) => rowData.route_string}
                actions={this.actions}
            />

            {!!usersDialog && (
                <ConfirmDialog title={'Użytkownicy, którzy otrzymali powiadomienie'}
                               dialogProps={{ maxWidth: 'md' }}
                               open={true}
                               isContentText={false}
                               onClose={this.closeDialogs}
                               cancelTitle={false}
                               onConfirm={this.closeDialogs}
                               confirmTitle={false}>
                    <Typography style={{ marginBottom: '1em' }}>{usersDialog.notification.content}</Typography>
                    <DataTable
                        options={{
                            toolbar: false,
                        }}
                        columns={this.getTableColumns({
                            'user.full_name': 'Użytkownik',
                            'user.id': 'ID',
                            'user.email': 'Email',
                            seen_at: 'Data odczytania',
                            is_sent: { title: 'E-mail wysłano?', lookup: { 0: 'Nie', 1: 'Tak' } },
                        })}
                        data={usersDialog.list}
                        showLink={rowData => '/users/list/' + rowData.user_id + '/edit'}
                    />
                </ConfirmDialog>
            )}
        </>
    }
}

export default NotificationList;
